:root {
  --black: #000;
  --light-black: #2b2e48;
  --medium-black: #2b2b2b;
  --white: #fff;
  --purple: #8762e9;
  --dark-purple: #28104e;
  --light-purple: #89879d;
  --beige: #7d7d7d;
  --gray: #757575;
  --text-color: #404040;
  --pink: #bdb3cd;
}

// color classes

.text-purple {
  color: var(--purples);
}
.text-black {
  color: var(--black);
}
.text-white {
  color: var(--white);
}
.text-light {
  color: rgba(0, 0, 0, 0.4);
}
.text-darkpurple {
  color: var(--dark-purple);
}
.text-lightpurple {
  color: var(--light-purple);
}
.text-light-black {
  color: var(--light-black);
}
.text-beige {
  color: var(--beige);
}
.text-md-black {
  color: var(--medium-black);
}
.text-gray {
  color: var(--gray);
}
.text-color {
  color: var(--text-color);
}
.text-pink {
  color: var(--pink);
}
