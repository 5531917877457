.camp-table {
  box-shadow: none;
  border-radius: 5px;
  width: 100%;
  .camp-email-thead {
    border-radius: 10px;
    th {
      padding: 17px 20px;
      background: rgba(135, 98, 233, 0.2);
      color: var(--Neutral-100, #0a0a0a);
      font-size: 16px;
      font-weight: 600;
      &:first-child {
        border-top-left-radius: 10px;
      }
      &:last-child {
        border-top-right-radius: 10px;
      }
    }
  }
  .camp-email-tbody {
    border: 1px solid #ededed;
    td {
      padding: 17px 20px;
      border-radius: 5px 5px 0px 0px;
      color: var(--Neutral-90, #404040);
    
      font-size: 16px;
      font-weight: 600;
      max-width: 133px;
      border-bottom: 1px solid #ededed;
    }
  }
}
.contacts-bottom-div {
  .camp-pagination {
    .ant-pagination-item {
      display: none !important;
    }
  }
}
@media only screen and (max-width: 1200px) {
  .camp-main-table {
    overflow-x: scroll;
    overflow-y: hidden;
    .camp-table {
      .camp-email-thead {
        th {
          font-size: 14px;
          max-width: 93px;
        }
      }
      .camp-email-tbody {
        td {
          font-size: 14px;
          max-width: 133px;
        }
      }
    }
  }
}
@media only screen and (max-width: 700px) {
  .camp-main-table {
    // overflow-x: scroll;
    // overflow-y: hidden;
    .camp-table {
      width: 100%;
      .camp-email-thead {
        th {
          padding: 10px 5px;
          font-size: 12px;
          max-width: 143px;
        }
      }
      .camp-email-tbody {
        td {
          padding: 10px 5px;
          font-size: 12px;
          max-width: 143px;
        }
      }
    }
  }
  .contacts-bottom-div {
    label {
      font-size: 12px;
    }
    .camp-pagination {
      display: none !important;
      .ant-pagination-item {
        display: none !important;
      }
    }
  }
}
