.purchase-modal {
  .purchasemodal-content {
    background: #f2f2f2;
    padding: 54px;
    border-radius: 10px;
    box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.15);
    .purchase-card {
      border-radius: 27.574px;
      background: #fff;
      width: 299px;
      padding: 27px 35px;
      h4 {
        margin-bottom: 24px;
      }
      span {
        span {
          align-items: flex-end;
        }
      }
      .amt-txt {
        margin-top: 40px;
        gap: 5px;
        span {
          align-items: baseline;
        }
      }
      button {
        border-radius: 9.191px;
        background: #f8f4ff;
        text-align: center;
          font-size: 18.383px;
        font-weight: 700;
        width: 100%;
        padding: 17px;
      }
    }
    .enterprise-card {
      background: linear-gradient(94deg, #3f1b78 -0.53%, #140231 101.1%);
      button {
        background: #5e369c;
        color: #fff;
      }
    }
  }

  .purchase-page{
    box-shadow: none !important;
    border-top: 1px solid #e5e5e5;
    margin-top: 20px;
  }
}
@media only screen and (max-width: 1400px) {
  .purchase-modal {
    width: 70vw !important;
  }
}
@media only screen and (max-width: 1200px) {
  .purchase-modal {
    width: 85vw !important;
  }
}
@media only screen and (max-width: 1024px) {
  .purchase-modal {
    .purchasemodal-content {
      padding: 50px 20px 30px 20px;
      flex-wrap: wrap;
      .purchase-card {
        width: 289px;
      }
    }
  }
}
