body {
  font-family: "DM Sans", sans-serif;
  background: #f4f4f4;
}

html {
  scroll-behavior: smooth;
}

a {
  color: #28104e;
  font-weight: 500;
}

// ---------------custom margins

.mt-54 {
  margin-top: 54px;
}

.mt-16 {
  margin-top: 16px;
}

.mt-24 {
  margin-top: 24px;
}

.mt-2 {
  margin-top: 5px;
}

//gap classes
.gap-8 {
  gap: 8px;
}

.gap-36 {
  gap: 36px;
}

.gap-16 {
  gap: 16px;
}

.gap-24 {
  gap: 24px;
}

// ---------------custom image sizes
.img-100 {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

//width and height classes
.w-100 {
  width: 100%;
}

.w-400 {
  max-width: 400px;
  width: 100%;
  height: 60px;
}

// Flex classes
.d-flex {
  display: flex;
}

.flex-column {
  flex-direction: column;
}

.h-center {
  display: flex;
  justify-content: center;
}

.v-center {
  display: flex;
  align-items: center;
}

.space-between {
  justify-content: space-between;
}

.text-underline {
  text-decoration: underline;
}

.cursor-pointer {
  cursor: pointer;
}

//margin classes

.m-auto {
  margin: auto;
}

.ml-auto {
  margin-left: auto;
}

.mr-auto {
  margin-right: auto;
}

.mx-2 {
  margin: 0px 5px;
}

.logo {
  width: 79px;
  height: 88.229px;
}

.logo-white {
  width: 82.286px;
  height: 89.072px;
}

.text-center {
  text-align: center;
}

.overflow-x-hidden {
  overflow-x: hidden !important;
}

.overflow-y-hidden {
  overflow-y: hidden !important;
}

.text-danger-sm {
  font-size: 14px;
  color: rgb(211, 0, 0) !important;
  padding: 5px 0px;
}

.signup-title {
  text-align: center;
  font-weight: 900;
  font-size: 42px !important;
}

.false-icon {
  color: #28104e;
  font-size: 24px;
}

.w-full {
  width: 100%;
}

.btn-disable {
  opacity: 0.6;
  pointer-events: none !important;
  cursor: not-allowed;
}

.danger {
  // background-color: red;
  border-color: rgb(82, 82, 82) !important;
  color: rgb(82, 82, 82) !important;
}

.custom-dropdown {
  width: fit-content;
  object-fit: cover;
  overflow-y: auto;
  max-height: 200px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  background-color: white !important;
  top: 110%;
  left: 50%;
  transform: translateX(-50%);
  text-wrap: nowrap;
  white-space: nowrap;

  button {
    width: 100%;
    border-bottom: 1px solid rgb(219, 219, 219);
    text-wrap: nowrap;
  }

  button:last-child {
    border-bottom: none;
  }
}

.custom-dropdown::-webkit-scrollbar {
  width: 6px;
  /* Adjust this value to make the scrollbar thinner */
}

.custom-dropdown::-webkit-scrollbar-track {
  background: #f1f1f100;
  /* Track color */
}

.custom-dropdown::-webkit-scrollbar-thumb {
  background: #bababa;
  /* Thumb color */
  border-radius: 30px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.spinner {
  width: 30px;
  height: 30px;
  border: 3px solid rgb(109, 109, 109);
  border-top: 3px solid transparent;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

.google-loading {
  width: 35px;
  height: 35px;
  border: 4px solid rgb(255, 255, 255);
  background-color: transparent;
  border-top: 3px solid transparent;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

.loader-bg {
  width: 100%;
  height: 400px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  .loader {
    width: 56px;
    height: 56px;
    z-index: 1000;
    display: grid;
    border-radius: 9999px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    animation: loader-plncf9 4s infinite;
  }

  .loader::before,
  .loader::after {
    content: "";
    grid-area: 1/1;
    border: 9px solid;
    border-radius: 50%;
    border-color: #54099b #54099b #0000 #0000;
    mix-blend-mode: darken;
    animation: loader-plncf9 1s infinite linear;
  }

  .loader::after {
    border-color: #0000 #0000 #dbdcef #dbdcef;
    animation-direction: reverse;
  }

  @keyframes loader-plncf9 {
    100% {
      transform: rotate(1turn);
    }
  }
}

.hidden {
  display: none !important;
}

.fixed {
  position: fixed;
  top: 20px;
  right: 0;
  z-index: 99;
}

.page-dom {
  margin-top: 70px;
}

.text-dim {
  opacity: 0.3;
}

.ant-color-picker-trigger {
  border: none !important;
}

.ql-toolbar.ql-snow {
  border-left: none;
  border-right: none;
  // white-space: nowrap;
  // overflow-x: scroll;
  // position: relative;
  // text-wrap: nowrap;

  &::-webkit-scrollbar {
    height: 4px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: rgb(197, 197, 197);
    border-radius: 30px;
  }
}

.disable-image {
  .ql-toolbar.ql-snow .ql-formats .ql-image {
    opacity: 0.3;
    cursor: none !important;
    pointer-events: none !important;
    // display: none;
  }
}

// .ant-select {
//   display: none !important;
// }

.ant-checkbox-wrapper {
  .ant-checkbox {
    width: 20px;
    height: 20px;

    .ant-checkbox-inner {
      width: 100% !important;
      height: 100% !important;

      &::after {
        border: 3px solid #fff;
        border-top: 0;
        border-inline-start: 0;
        content: "";
        left: 6px;
        transform: rotate(45deg) scale(1) translate(-50%, -50%);
      }
    }
  }

  .ant-checkbox-checked {
    .ant-checkbox-inner {
      background-color: #28104e;
      border: #28104e;
    }
  }
}

.ant-checkbox .ant-checkbox-inner:after .loader {
  width: 100%;
  height: 100%;
  top: 0 !important;
  background-color: rgba(231, 230, 230, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.text-error {
  font-size: 13px;
}

.ql-snow.ql-toolbar button,
.ql-snow .ql-toolbar button {
  width: fit-content !important;
}

.ql-snow.ql-toolbar .ql-btn2-0,
.ql-snow.ql-toolbar .ql-btn2-1 {
  width: 50px !important;
  margin-left: -20px;

  button {
    background: linear-gradient(#590077, #0000f9) !important;
    padding-right: 30px !important;
    -webkit-background-clip: text !important;
    -webkit-text-fill-color: transparent !important;
  }
}

.text-sm {
  font-size: 14px;
}

// .ql-toolbar.ql-snow span button{
//   margin: 0px 5px;
// }
.lock {
  font-size: 30px;
}

.word-counts {
  font-size: 13px;
  font-weight: 500;
  font-style: italic;
  padding: 10px 0px;
  color: gray;
}

.bg-purple {
  background-color: #28104e !important;
  color: white !important;
  opacity: 0.7;
  cursor: not-allowed !important;
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: center;
}

.premium {
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: center;
  position: relative;
}

.premium .icon,
.premium span {
  letter-spacing: 10px;
  font-size: 13px;
  font-weight: 600;
  position: relative;
  overflow: hidden;
  /* Clip any overflow from shine effect */
}

.premium .icon {
  position: relative;
  /* Ensure positioning context for absolute */
}

.premium.blush .icon::after,
.premium.blush span::after {
  content: "";
  position: absolute;
  top: 50%;
  /* Position in the middle vertically */
  left: 50%;
  /* Position in the middle horizontally */
  transform: translate(-50%, -50%) rotate(45deg);
  /* Center and rotate */
  width: 200%;
  /* Adjust width to cover adequately */
  height: 200%;
  /* Adjust height to cover adequately */
  background: rgba(255, 255, 255, 0.5);
  /* Adjust shine color and opacity */
  z-index: 1;
  animation: shine 2s linear forwards infinite;
  /* Adjust animation properties */
}

@keyframes shine {
  0% {
    transform: translate(-150%, -50%) rotate(45deg);
    /* Start position */
    opacity: 0.5;
    /* Start opacity */
  }

  50% {
    transform: translate(150%, -50%) rotate(45deg);
    /* Middle position */
    opacity: 1;
    /* Middle opacity */
  }

  100% {
    transform: translate(450%, -50%) rotate(45deg);
    /* End position */
    opacity: 0.5;
    /* End opacity */
  }
}

.ant-dropdown .ant-dropdown-menu,
.ant-dropdown-menu-submenu .ant-dropdown-menu {
  max-height: 400px !important;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: rgb(197, 197, 197);
    border-radius: 30px;
  }
}

@media (max-width: 1540px) {
  .ql-toolbar.ql-snow span button {
    margin: 0px 7px;
  }

  .ql-snow.ql-toolbar .ql-btn2-0,
  .ql-snow.ql-toolbar .ql-btn2-1 {
    button {
      padding-right: 0 !important;
    }
  }
}

// .ant-modal .ant-modal-body{
//   width: 100% !important;
// }

// .ant-modal-root .ant-modal-wrap{
//   top: 50%;
//   left: 50%;
//   transform: translate(-50%, -50%);
// }

// ------------------------ 404 --------------------------
.page-404 {
  width: 100%;
  height: 100vh;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  h1 {
    font-size: 120px;
    letter-spacing: 20px;
  }

  p {
    font-size: 30px;
    letter-spacing: 20px;
  }
}

.ant-pagination .ant-pagination-options-size-changer {
  display: none;
}

.btn-spinner {
  width: 20px;
  height: 20px;
  border: 3px solid rgba(255, 255, 255, 0.6);
  border-top: 2px solid transparent;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

// ----------------------- Approve Modal SCSS -----------------
.modal-content {
  .approve-modal {
    width: 100%;
    padding-top: 20px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 12px;

    p {
      margin: 0 !important;
      width: calc(100% - 25px);
      max-width: 100% !important;
      // text-align: center;
      font-size: 13px;
    }
  }
}

.ant-checkbox-wrapper .ant-checkbox {
  width: 15px !important;
  height: 15px !important;
}

.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner {
  border: 1px solid #28104e !important;
}
.ant-checkbox-wrapper .ant-checkbox-checked .ant-checkbox-inner:hover {
  background-color: #28104e !important;
  border: 1px solid #28104e !important;
}
.ant-checkbox-wrapper-checked:hover .ant-checkbox-inner,
.ant-checkbox-checked:hover .ant-checkbox-inner {
  background-color: #28104e !important;
  border: 1px solid #28104e !important;
}
.ant-checkbox-wrapper .ant-checkbox .ant-checkbox-inner::after {
  border: 3px solid white;
  border-top: 0;
  border-inline-start: 0;
  content: "";
  left: 3.8px;
  top: 7px;
  transform: rotate(45deg) scale(1) translate(-50%, -50%);
}

.ant-checkbox-wrapper .ant-checkbox-checked .ant-checkbox-inner {
  width: 16px !important;
}
