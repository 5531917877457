.email-analytics-main {
  padding: 50px 50px 20px 0px;
  .email-analytics-left {
    border-radius: 20px;
    background: #fff;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);
    padding: 24px;
    width: 70%;
    gap: 30px;
    .camaign-tabele-main {
      border-radius: 6px;
      border: 1px solid var(--Border-Gray, #e6e6e6);
      background: var(--neutral-02-white, #fff);
      box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.2);
      gap: 24px;
      padding: 15px 24px;
      width: 90%;
      .inner-table {
        gap: 24px;
      }
    }
  }
  .email-analytics-right {
    border-radius: 10px;
    border: 1px solid var(--Border-Gray, #e6e6e6);
    background: var(--neutral-02-white, #fff);
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);
    padding: 24px;
    width: 25%;
    gap: 24px;
    p {
      color: var(--Neutral-90, #404040);
      font-size: 14px;
      font-weight: 600;
    }
    .sends-divs {
      h6 {
        color: var(--Neutral-90, #404040);
      }
      p {
        padding: 16px;
        border-radius: 8px;
        border: 1px solid var(--Neutral-50, #c2c2c2);
        background: var(--Neutral-10, #fff);
        font-size: 16px;
        font-weight: 600;
      }
    }
  }
}
@media only screen and (max-width: 1400px) {
  .email-analytics-main {
    padding: 50px 20px 20px 20px !important;
    .email-analytics-left {
      width: 70%;
      padding: 24px 16px;
      .camaign-tabele-main {
        padding: 15px 15px;
        width: 95%;
      }
    }
    .email-analytics-right {
      width: 25%;
      p {
        font-size: 12px;
      }
      .sends-divs {
        h6 {
          font-size: 16px;
        }
        p {
          font-size: 14px;
        }
      }
    }
  }
}
@media only screen and (max-width: 700px) {
  .email-analytics-main {
    padding: 30px 20px;
    flex-direction: column;
    gap: 24px;
    .email-analytics-left {
      width: 100%;
    }
    .email-analytics-right {
      width: 100%;
    }
  }
}
