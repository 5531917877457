.Dashboard-main-container {
  color: #000000;
  min-height: 100vh;
  position: relative;
  .left-content {
    padding: 15px 0px !important;
  }
  .right_content {
    padding: 15px 0px 0px 0px;
    width: calc(100vw - 346px);
    margin-left: 346px !important;
  }
}
@media only screen and (max-height: 800px) {
  .Dashboard-main-container {
    .right_content {
      width: calc(100vw - 250px) !important;
      margin-left: 286px !important;
    }
  }
}
@media only screen and (max-width: 1023px) {
  .Dashboard-main-container {
    .right_content {
      width: 100% !important;
      margin-left: 40px !important;
    }
  }
}

@media only screen and (max-width: 696px) {
  .Dashboard-main-container {
    .right_content {
      width: 100% !important;
      margin-left: 10px !important;
    }
  }
}