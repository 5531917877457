.campaign-history-main {
  margin: 50px 0px;
  margin-top: 150px;
  width: 100%;
  .campaign-inner {
    width: 90%;
    padding: 24px;
    border-radius: 6px;
    border: 1px solid var(--Border-Gray, #e6e6e6);
    background: var(--neutral-02-white, #fff);
    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.25);
    gap: 24px;
  }
}
@media only screen and (max-width: 1400px) {
  .campaign-history-main {
    padding: 30px 24px;
    .campaign-inner {
      width: 100%;
    }
  }
}
@media only screen and (max-width: 700px) {
  .campaign-history-main {
    padding: 10px 20px;
  }
}
