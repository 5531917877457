.start-campaign-main-conatiner {
  padding: 45px 49px 15px 0px;

  .start-left-section {
    gap: 44px;
    width: 65%;

    .start-left-top {
      .back-arrow {
       font-size: 30px;
       cursor: pointer;
      }
    }

    .start-left-bottom {
      flex-direction: row-reverse;

      button {
        padding: 12px 30px;
      }
    }
  }

  .start-right-section {
    width: 25%;
    height: 700px;
    overflow-y: auto;
    border-radius: 6px;
    border: 1px solid var(--Border-Gray, #e6e6e6);
    background: var(--neutral-02-white, #fff);
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);
    padding: 24px;
    gap: 25px;

    &::-webkit-scrollbar {
      width: 6px;
    }

    &::-webkit-scrollbar-track {
      background: transparent;
      margin: 5px;
    }

    &::-webkit-scrollbar-thumb {
      background: rgb(185, 185, 185);
      border-radius: 30px;
    }

    .schedule-conatiner {
      .settings-fields {
        width: 100%;
        padding: 14px;
        border-radius: 7.05px;
        border: 1.762px solid #ededed;
        background: var(--white);

        input {
          border: none;
          width: 15vw !important;
          color: #2a2f3b;
        
          font-size: 14px;
          font-weight: 400;
        }

        .react-datepicker__day--selected {
          background-color: var(--dark-purple) !important;
          border-radius: 7.057px;
          color: var(--white) !important;
        }

        .react-datepicker__day {
          border-radius: 7.057px;
          background: #f5f5f5;
          color: #131a29;
        }
      }

      .down-icon {
        font-size: 24px;
      }

      .preview-btn {
        &:hover {
          color: #f2f2f2 !important;
          border-radius: 8px !important;
          border: 1.76px solid var(--SSS, #3f1b78) !important;
          background: var(--SSS,
              linear-gradient(94deg, #3f1b78 -0.53%, #140231 101.1%)) !important;
          transition: 0.5s ease-in-out !important;
        }
      }
    }

    .active-days-wrapper {
      gap: 25px;

      span {
        padding: 14px;
        border-radius: 7.05px;
        border: 1.762px solid #ededed;
        background: var(--white);

        select {
          border: none;
          color: #c2c2c2;
        
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          max-width: 70% !important;
          overflow: hidden !important;

          &:focus {
            outline: none;
          }
        }
      }
    }

    .preview-btn {
      border-radius: 8px;
      border: 1.76px solid #ededed;
      background: var(--Neutral-10, #fff);
      color: #2b2b2b;
    
      font-size: 16px;
      padding: 16px;
      font-weight: 600;
    }

    .parameter-conatainer {
      gap: 25px;

      span {
        color: #2b2b2b;
      
        font-size: 16px;
        font-weight: 600;
        border-radius: 8px;
        border: 1.76px solid #ededed;
        background: var(--Neutral-10, #fff);
        padding: 16px;
      }

      .add-click-div {
        gap: 5px;

        input {
          border-radius: 8px;
          border: 1.76px solid #ededed;
          background: var(--Neutral-10, #fff);
          color: #c2c2c2;
        
          font-size: 16px;
          font-weight: 600;
          padding: 16px;
          width: 40%;
        }
      }
    }
  }
}

@media only screen and (max-width: 1700px) {
  .start-campaign-main-conatiner {
    .start-right-section {
      width: 29%;
    }
  }
}

@media only screen and (max-width: 1540px) {
  .start-campaign-main-conatiner {
    .start-left-section {
      width: 60%;
    }

    .start-right-section {
      width: 33%;
    }
  }
}

@media only screen and (max-width: 1320px) {
  .start-campaign-main-conatiner {
    padding: 45px 19px 15px 0px;

    .start-left-section {
      width: 64%;

      .start-left-top {
        button {
          width: 138px;
          font-size: 12px !important;
        }
      }

      .start-left-bottom {
        button {
          font-size: 12px !important;
        }
      }
    }

    .start-right-section {
      width: 33%;
      padding: 20px;

      .schedule-conatiner {
        .date-picker {
          padding: 14px 8px;
        }
      }

      .active-days-wrapper {
        span {
          padding: 14px 8px;

          label {
            font-size: 14px;
          }

          select {
            font-size: 14px;
          }
        }
      }

      .parameter-conatainer {
        .add-click-div {
          gap: 5px;

          input {
            padding: 12px 6px;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 700px) {
  .start-campaign-main-conatiner {
    padding: 30px 20px;
    flex-direction: column;
    gap: 40px;

    .start-left-section {
      gap: 34px;
      width: 96%;

      h4 {
        font-size: 20px;
      }

      .start-left-top {
        button {
          width: 138px;
        }
      }

      .start-left-bottom {
        margin-top: -15px;
      }
    }

    .start-right-section {
      width: 100%;
      padding: 24px;
      gap: 25px;

      .schedule-conatiner {
        .date-picker {
          padding: 14px;

          input {
            width: 70vw !important;
          }
        }
      }
    }
  }
}