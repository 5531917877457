.profile-main-container {
  margin-top: 6px;

  .profile-left-container {
    width: 50%;
    gap: 18px;

    .profile-dp-container {
      padding: 19px 19px 36px 19px;

      .purple-container {
        border-radius: 14.853px;
        background: var(--SSS,
            linear-gradient(94deg, #3f1b78 -0.53%, #140231 101.1%));
        height: 119.752px;
      }

      .profile-name-container {
        margin-top: -50px;

        .profile-inner {
          position: relative;

          .img-wrapper {
            width: 80px;
            height: 80px;
            border-radius: 50px;
            display: flex;
            justify-content: center;
            overflow: hidden;

            .user-dp-img {
              height: 100%;
            }
          }

          .user-dp-icon {
            font-size: 76px;
            color: rgb(182, 182, 182);
            background-color: white;
            border-radius: 999px;
          }

          .profile-editIcon {
            position: absolute;
            bottom: 5%;
            right: -2%;
            width: 30px;
            height: 30px;
          }
        }

        span {
          font-size: 12.996px;
        }
      }
    }

    .account-setting-container {
      padding: 19px 19px 42px 19px;

      .accountsetting-inner {
        .username-email-div {
          margin-top: 35px;
        }

        .name-div {
          margin-top: 26px;
        }

        .jobtitle-div {
          margin-top: 16px;

          .ant-select-selector,
          .ant-select-selector:focus {
            padding: 3.068px 19.495px;
            border-radius: 11.14px;
            border: 0.928px solid #e2e8f0;
                  height: 40.846px;
            font-size: 12.996px;
            font-weight: 500;
            width: 100%;
            color: #7d7d7d;
            box-shadow: none;
          }

          .ant-select-arrow {
            margin-top: -1px !important;
          }

          .ant-select-selection-item {
            color: black;
          }
        }
      }
    }
  }

  .profile-right-container {
    width: 50%;
    gap: 18px;

    .company-info-container,
    .change-pswd-container {
      padding: 19px 19px 42px 19px;

      p {
        font-size: 14.853px;
        font-weight: 500;
      }

      .compinfo-inner,
      .changepswd-inner {
        gap: 22px;

        p {
          font-size: 12.996px;
          font-weight: 700;
        }

        span {
          &:first-child {
            margin-top: 35px;
          }
        }
      }
    }
  }
}

.profile-bottom-container {
  padding: 18px;

  button {
    padding: 16.904px 48.344px 17.629px 48.662px;

    &:first-child {
      border-radius: 41.774px;
      background: #fff5f5;
      color: #e31a1a;
      font-size: 12.996px;
    }
  }
}

.profile-bg-radius {
  border-radius: 12.996px;
  background: #fff;
  box-shadow: 12.996px 15.781px 37.132px 3.713px rgba(112, 144, 176, 0.08);
}

.input-profile-password {
  position: relative;

  .Peye-icon {
    position: absolute;
    top: 30%;
    right: 4%;
  }
}

input:-internal-autofill-selected {
  background: transparent !important;
  background-color: transparent !important;
}

@media only screen and (max-width: 700px) {
  .profile-main-container {
    flex-direction: column;
    gap: 24px;

    .profile-left-container {
      width: 100%;

      .account-setting-container {
        padding: 19px 19px 42px 19px;

        .accountsetting-inner {

          .username-email-div,
          .name-div {
            flex-direction: column;
          }
        }
      }
    }

    .profile-right-container {
      width: 100%;
    }
  }

  .profile-bottom-container {
    button {
      padding: 16px;
    }
  }
}