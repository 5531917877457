@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.4/css/all.min.css");

@font-face {
  font-family: "DM Sans";
  src: url("../../../public/assets/fonts/DMSans.ttf");
}

* {
  line-height: normal;
  font-family: "DM Sans", sans-serif;
}

button {
  cursor: pointer;
}

h2 {
  font-size: 50px;
  font-weight: 400;
}

h2.small {
  font-size: 48px;
  font-weight: 700;
}

h3 {
  font-size: 36px;
  font-weight: 700;
}

h4 {
  font-size: 27px;
  font-weight: 700;
}

h4.elarge {
  font-size: 24px;
  font-weight: 700;
  color: #2b2e48;
}

h5.large {
  font-size: 24px;
  font-weight: 600;
}

h5 {
  font-size: 24px;
  font-weight: 400;
}

h6 {
  font-size: 22px;
  font-weight: 700;
}

h6.medium-head {
  font-size: 20px;
  font-weight: 600;
}

label.elarge {
  font-size: 18px;
  font-weight: 700;
}

label.large {
  font-size: 18px;
  font-weight: 400;

}

.label {
  font-size: 17px;
  font-weight: 400;
}

label.small {

  font-size: 16px;
  font-weight: 400;
}

p {
  font-size: 20px;
  font-weight: 400;
}

.p-med {
  font-size: 14px;
  font-weight: 600;
}

.p-small {
  font-size: 10.395px;
  font-weight: 400;
}

.body-large {
  font-size: 13px;
  font-weight: 500;
}

.body-med {
  font-size: 12.693px;
  font-weight: 700;
}

.body-small {
  font-size: 12.693px;
  font-weight: 400;
}

.link {
  color: #28104e;
  font-weight: 600;
  text-decoration: underline;
}