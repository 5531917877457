.dashboard-main {
  padding: 50px 0px;
  position: relative;

  .campagins-inner {
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;

    .camp-btns {
      gap: 24px;
      margin-top: 26px;

      .generate-btn {
        width: 234px;
        height: 45px;

        font-size: 16px;
        font-weight: 600;
        border-radius: 41px;
        position: relative;
        background: radial-gradient(50% 50% at 50% 50%,
            rgba(164, 124, 243, 0.25) 0%,
            rgba(40, 16, 78, 0.25) 100%);
        box-shadow: 0px 0px 40px 0px rgba(153, 23, 255, 0.25),
          0px 0px 0.745px 2.982px rgba(255, 255, 255, 0.1),
          0px -2.982px 1.491px 0px rgba(0, 0, 0, 0.25) inset,
          0px 1.491px 0.745px 0px rgba(255, 255, 255, 0.25) inset;

        &::before {
          position: absolute;
          content: "";
          background-image: url(../../../../../public/assets/images/dashboardimages/modalAIBtn1.png);

          background-size: cover;
          width: 236px;
          height: 45px;
          top: 0;
          right: 0;
          background-repeat: no-repeat;
          pointer-events: none;
        }
      }

      .template-btn {
        cursor: pointer;
        width: 234px;
        height: 45px;
        text-align: center;

        font-size: 16px;
        font-weight: 600;
        border-radius: 41px;
        transition: 0.3s ease-in-out;
        position: relative;

        &:first-child {
          background: radial-gradient(50% 50% at 50% 50%,
              rgba(164, 124, 243, 0.25) 0%,
              rgba(40, 16, 78, 0.25) 100%);
          box-shadow: 0px 0px 40px 0px rgba(153, 23, 255, 0.25),
            0px 0px 0.745px 2.982px rgba(255, 255, 255, 0.1),
            0px -2.982px 1.491px 0px rgba(0, 0, 0, 0.25) inset,
            0px 1.491px 0.745px 0px rgba(255, 255, 255, 0.25) inset;

          // animation: changeBackground 0.4s infinite;
          // @keyframes changeBackground {
          //   0% {
          //     background-image: url(../../../../../public/assets/images/dashboardimages/createbtnBg1.png);
          //   }
          //   25% {
          //     background-image: url(../../../../../public/assets/images/dashboardimages/createbtnBg2.png);
          //   }
          //   50% {
          //     background-image: url(../../../../../public/assets/images/dashboardimages/createbtnBg3.png);
          //   }
          //   75% {
          //     background-image: url(../../../../../public/assets/images/dashboardimages/createbtnBg4.png);
          //   }
          //   100% {
          //     background-image: url(../../../../../public/assets/images/dashboardimages/createbtnBg1.png);
          //   }
          // }
          &::before {
            position: absolute;
            content: "";
            background-image: url(../../../../../public/assets/images/dashboardimages/createbtnBg1.png);
            background-size: cover;
            width: 234px;
            height: 45px;
            top: 0;
            right: 0;
            background-repeat: no-repeat;
            pointer-events: none;
          }
        }
      }
    }

    .active-compaigns {
      width: 90%;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 20px;
      padding: 50px 80px 50px 50px;

      table {
        overflow: hidden !important;

        tbody {
          background-color: white;
        }
      }
      .campaign-noplan-text{
        font-size: 18px;
      }
    }
  }
}

.campaign-details {
  padding: 50px 0px;
  width: 95%;
  font-family: 'DM Sans', sans-serif;

  .details-header {
    margin-bottom: 40px;
    font-style: italic;

    h3 {
      font-weight: bolder;
      padding-top: 20px;
      font-size: 24px !important;
      color: #2b2e48;
    }

    p {
      font-size: 14px;
      padding: 5px 0;
      letter-spacing: 1.15px;
    }

    .compaign-created {
      margin-top: 20px;
      border-left: 3px solid #2b2e48;
      padding: 10px 15px;
      font-size: 12px;
      font-style: normal;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      gap: 3px;
      background: linear-gradient(to right,
          rgb(227, 227, 227),
          transparent 15%);

      span {
        font-size: 14px;
        font-weight: 600;
        letter-spacing: 1.5px;
        color: rgb(120, 120, 120);
      }
    }
  }

  .compaign-detail-overview {
    margin-top: 30px;
    padding: 20px;

    .overview-title {
      font-size: 16px;
      font-weight: 600;
      letter-spacing: 1.15px;
      color: #2b2e48;
    }

    .overview-container {
      display: flex;
      margin: 20px 0;
      align-items: center;
      justify-content: space-between;
      gap: 20px;
      padding: 40px 60px;
      background-color: #e7e5e5;
      border-radius: 10px;

      .overview-item {
        display: flex;
        flex-direction: column;
        gap: 5px;
        align-items: center;
        justify-content: center;

        .overview-icon {
          img {
            width: 50px;
            height: 50px;
          }
        }

        .overview-label {
          font-size: 14px;
          font-weight: 700;
          color: rgb(0, 0, 0);
        }

        .overview-counts {
          font-size: 14px;
          font-weight: 600;
          opacity: 0.6;
        }
      }
    }
  }

  .receipent-data {
    margin-top: 30px;
    padding: 20px;
    overflow: hidden;
    width: 100%;

    .receipent-title {
      font-size: 16px;
      font-weight: 600;
      letter-spacing: 1.15px;
      color: #2b2e48;
    }

    .receipent-table {
      margin: 20px 0px;
      background-color: #f0efef;
      border-radius: 10px;
      overflow: auto;
      width: 100%;

      &::-webkit-scrollbar {
        height: 4px;
      }

      &::-webkit-scrollbar-track {
        background: transparent;
      }

      &::-webkit-scrollbar-thumb {
        background: rgb(197, 197, 197);
        border-radius: 30px;
      }
    }

    .success-badge {
      margin: 0 !important;
      background-color: rgba(0, 128, 0, 0.2);
      padding: 3px 8px 4px 8px;
      letter-spacing: 1.25px;
      border-radius: 30px;
      border: 1px solid green;
      color: rgb(0, 79, 0);
      font-weight: 700;
      width: fit-content;
    }
  }
}

.campaign-details-modal {

  .ant-modal-close,
  .ant-modal-close:hover {
    color: #000000;
  }

  .details-container {
    padding: 20px;
    height: 80vh;
    overflow-y: auto;

    .detail-body {
      width: 100%;
      display: flex;
      justify-content: space-between;
      gap: 20px;
      // margin-top: 30px;
      padding: 20px;

      .detail-template {
        .template-head {
          display: flex;
          align-items: center;
          gap: 4px;

          .template-title {
            padding: 10px 0;
            margin: 0;
            font-size: 16px;
            font-weight: 700;
            color: #2b2e48;
          }

          span {
            font-size: 12px;
          }
        }

        p {
          font-size: 14px !important;
        }
      }
    }
  }
}

@media (max-width: 850px) {
  .campaign-details {
    .details-header {
      h3 {
        font-size: 20px !important;
      }

      p {
        font-size: 12px;
      }

      .compaign-created {
        font-size: 10px;

        span {
          font-size: 12px;
        }
      }
    }

    .compaign-detail-overview {
      .overview-title {
        font-size: 30px;
        font-weight: 600;
        letter-spacing: 1.15px;
        color: #2b2e48;
      }

      .overview-container {
        flex-direction: column;
        padding: 20px 30px;

        .overview-item {
          text-align: center;

          .overview-icon {
            img {
              width: 30px;
              height: 30px;
            }
          }
        }
      }
    }
  }

  .campaign-details-modal {
    .detail-body {
      flex-direction: column;
    }
  }
}

@media only screen and (max-width: 700px) {
  .dashboard-main {
    padding: 20px;
    position: relative;

    .campagins-inner {
      p {
        font-size: 16px;
      }

      .camp-btns {
        flex-direction: column;
        align-items: center;
      }

      .active-compaigns {
        padding: 30px;

        .w-full {
          overflow: auto !important;

          table {

            tbody {
              background-color: white;
            }
          }

          &::-webkit-scrollbar {
            height: 4px;
          }

          &::-webkit-scrollbar-track {
            background: transparent;
          }

          &::-webkit-scrollbar-thumb {
            background: rgb(197, 197, 197);
            border-radius: 30px;
          }

          .contacts-bottom-div {
            gap: 20px;
            flex-direction: column;
          }
        }
      }
    }
  }
}