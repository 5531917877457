.input-element {
  border-radius: 10px;
  border: 1px solid rgba(0, 0, 0, 0.4);
  max-width: 400px;
  width: 100%;
  height: 60px;
  padding: 10px 20px;
  color: black;
  background: transparent;
}
.email-input,
.email-input::placeholder {
  color: var(--medium-black);
  font-size: 16px;
  font-weight: 400;
  border: none;
  color: rgba(0, 0, 0, 0.4);
}

.profile-input {
  padding: 3.068px 19.495px;
  border-radius: 11.14px;
  border: 0.928px solid #e2e8f0;
  height: 40.846px;
  font-size: 12.996px;
  font-weight: 500;
  width: 100%;
  color: black;
  &::placeholder {
    color: #7d7d7d;
  }
}
@media only screen and (max-width: 700px) {
  .input-element {
    height: 50px;
  }
}
