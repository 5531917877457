.header-bg {
  width: 100vw;
  height: 120px;
  top: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  position: fixed;
  z-index: 20;
}

.dashnavbar-container {
  width: calc(100% - 320px);
  padding: 20px 24px;
  border-radius: 41px 0px 0px 41px;
  background: #fff;
  justify-content: right;
  box-shadow: 0px 0px 10px #2b2b2b10;

  .dashnav-left {
    h5 {
      color: #2b2b2b;
      font-size: 16px;
      font-weight: 700;
      text-transform: uppercase;
    }
  }

  .dashnav-right {
    gap: 24px;

    .nav-search {
      border-radius: 41px;
      border: 1px solid #dcdcdd;
      background: #f2f2f2;
      padding: 10px;

      input {
        background: transparent;
        border: none;
      }
    }

    .trophy-div {
      margin-left: 16px;
      gap: 10px;

      span {
        padding: 5px 18px;
        border-radius: 41px;
        border: 1px solid #fed843;
      }
    }

    .notification-section {
      cursor: pointer;
      position: relative;

      .noti-icon {
        width: 24px;
        height: 24px;
      }

      span {
        position: absolute;
        top: -3px;
        width: 15px;
        height: 15px;
        border-radius: 30px;
        background-color: rgb(177, 0, 0);
        right: -3px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: white;
        font-size: 12px;
        font-weight: 700;
      }
    }

    .nav-user-profile {
      .img-wrapper {
        width: 40px;
        height: 40px;
        border-radius: 30px;
        display: flex;
        justify-content: center;
        overflow: hidden;

        .user-dp-img {
          height: 100%;
        }
      }

      span {


        h5 {
          color: #2b2e48;
          font-size: 14px;
          font-weight: 700;
        }
      }

      .flex-vertical {
        display: flex;
        flex-direction: column;
        margin-top: 10px;
      }
    }
  }
}

.clear-all-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
  background-color: white;
  border-radius: 0px !important;
  padding: 5px;
  position: sticky;
  z-index: 20;
  top: -5px;

  .icon {
    font-size: 20px;
  }

  button {
    color: rgb(123, 23, 190);

    &:hover {
      color: rgb(169, 50, 248) !important;
    }
  }
}

.ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item:hover,
.ant-dropdown-menu-submenu .ant-dropdown-menu .ant-dropdown-menu-item:hover,
.ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-submenu-title:hover,
.ant-dropdown-menu-submenu .ant-dropdown-menu .ant-dropdown-menu-submenu-title:hover,
.ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item-active,
.ant-dropdown-menu-submenu .ant-dropdown-menu .ant-dropdown-menu-item-active,
.ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-submenu-title-active,
.ant-dropdown-menu-submenu .ant-dropdown-menu .ant-dropdown-menu-submenu-title-active {
  background: transparent;
}

.notification-item {
  width: 250px;
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 5px 0px;

  .notification-content {
    flex-grow: 1;
    width: 80%;
    text-wrap: initial;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .notification-contet {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    align-items: center;
    gap: 20px;
    padding: 20px 30px;

    .notification-bell {
      font-size: 44px;
      opacity: 0.6;
    }
  }

  .delete-icon {
    font-size: 20px;
    opacity: 0.8;
  }
}

.notification-icon {
  font-size: 30px;
  // margin-right: 12px;
}

.notification-content {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
}

.notification-time {
  font-size: 0.8em;
  color: #888;
}

@media only screen and (max-height: 800px) {
  .dashnavbar-container {
    padding: 15px 24px;
    width: calc(100% - 290px);

    .dashnav-right {
      .nav-search {
        padding: 8px;
      }
    }
  }
}

@media only screen and (max-width: 1023px) {
  .dashnavbar-container {
    justify-content: space-between;
    width: 95%;

    .dashnav-left {
      display: block !important;
    }
  }
}

@media only screen and (max-width: 700px) {
  .header-bg {
    height: 90px;
  }

  .notification-item {
    width: 100%;
  }
  .dashnavbar-container {
    padding: 10px 15px;
    margin-left: 10px;

    .dashnav-right {
      gap: 3px;

      .nav-search {
        padding: 5px;

        img {
          width: 12px;
          height: 12px;
        }

        input {
          width: 70px;
          font-size: 12px;
        }
      }

      .trophy-div {
        margin-left: 6px;
        gap: 6px;

        span {
          padding: 5px 14px;
          font-size: 12px;
        }

        .trophy-icon {
          width: 18px;
          height: 18px;
          margin-top: 3px;
        }
      }

      .noti-icon {
        width: 22px;
        height: 24px;
      }

      .nav-user-profile {
        span {
          display: none;
        }
      }
    }
  }
}