.contacts-main-conatiner {
  padding: 78px 24px 24px 24px;
  gap: 55px;
  width: 100%;
  .upload-contacts-div {
    .uplaod-conatiner {
      padding: 23.955px 35.932px 23.955px 47.91px;
      border-radius: 14.972px;
      border: 1.497px dashed rgba(0, 0, 0, 0.25);
      gap: 36px;
      width: 50%;
      .upload-right {
        width: 100%;
      }
    }
  }
  .spread-main {
    gap: 24px;
    .spreadsheet-div {
      padding: 24px;
      border-radius: 6px;
      border: 1px solid var(--Border-Gray, #e6e6e6);
      background: var(--neutral-02-white, #fff);
      box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.25);
      gap: 24px;
      width: 90%;
    }
  }
}
.ant-pagination-prev,
.ant-pagination-next {
  border-radius: 2px;
  border: 1px solid var(--Neutral-60, #9e9e9e);
  background: var(--Neutral-1, #fff);
  color: black;
}
.ant-pagination {
  display: flex;
}
.ant-pagination .ant-pagination-item {
  border-radius: 5px;
  border: 1px solid var(--Neutral-60, #9e9e9e);
  background: var(--Neutral-1, #fff);
  display: flex;
  justify-content: center;
  align-items: center;
}
.ant-pagination-item-active {
  border: 1px solid #28104e !important;
  background: #28104e !important;
  color: #fff;
}

@media only screen and (max-width: 1630px) {
  .contacts-main-conatiner {
    padding: 78px 20px 24px 20px;
    .upload-contacts-div {
      .uplaod-conatiner {
        padding: 23.955px 30.932px 23.955px 30.91px;
        gap: 25px;
        width: 55%;
        img {
          width: 44px;
          height: 44px;
        }
        .upload-right {
          p {
            font-size: 18px;
          }
          button {
            height: 50px;
            width: 130px;
            padding: 0px;
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 1400px) {
  .contacts-main-conatiner {
    .upload-contacts-div {
      gap: 10px !important;
      h4 {
        font-size: 18px;
      }
      .uplaod-conatiner {
        padding: 18.955px 16.932px 18.955px 16.91px;
        gap: 20px;
        width: 58%;
        img {
          width: 40px;
          height: 40px;
        }
        .upload-right {
          p {
            font-size: 16px;
          }
          button {
            height: 45px;
            width: 120px;
            padding: 0px;
            font-size: 12px !important;
          }
        }
      }
    }
    .spread-main {
      .spreadsheet-div {
        width: 100%;
      }
    }
  }
}
@media only screen and (max-width: 700px) {
  .contacts-main-conatiner {
    padding: 30px 20px;
    .upload-contacts-div {
      flex-direction: column;
      .uplaod-conatiner {
        padding: 10px;
        gap: 16px;
        width: 100%;
        .upload-right {
          width: 100%;
          button {
            padding: 10px !important;
          }
        }
      }
    }
    .spread-main {
      .spreadsheet-div {
        width: 100%;
      }
    }
  }

}