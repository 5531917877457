.sendEmail-modal {
  border-radius: 20px;
  background: #fff;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .email-modal-header {
    padding: 20px;
    background: #f3effd;
  }

  .preview-modal-content {
    padding: 20px;

    .sendbox-range {
      padding: 10px 0;
      text-align: right;
      font-size: 16px;
    }
    .main-table {
      overflow: hidden;
      border-radius: 10px;
      box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);

      .table-head {
        border-radius: 0 !important;
        z-index: 10;
      }
      .table-body {
        overflow-y: auto;
        max-height: calc(73vh - 250px);

        &::-webkit-scrollbar {
          width: 4px;
        }

        &::-webkit-scrollbar-track {
          background: transparent !important;
          border: none;
          box-shadow: none;
          margin: 10px 0;
        }

        &::-webkit-scrollbar-thumb {
          background: rgb(197, 197, 197);
          border-radius: 30px;
        }

        table {
          border-top-left-radius: 0 !important;
          border-top-right-radius: 0 !important;
        }
      }
    }

    .scrollable {
      overflow-y: auto;

      table {
        box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.15);
        border-radius: 5px;
        overflow: hidden;

        .send-email-thead {
          th {
            padding: 17px 20px;
            background: #f3f3f3;
            color: var(--Neutral-90, #404040);
          
            font-size: 14px;
            font-weight: 400;
            // max-width: 113px;
          }
        }
        tbody {
          display: table;
          overflow-y: auto;
          height: 300px;

          .send-email-tbody {
            td {
              padding: 17px 20px;
              border-radius: 5px 5px 0px 0px;
              color: var(--Neutral-90, #404040);
            
              font-size: 16px;
              font-weight: 400;
              // max-width: 113px;
            }
          }
        }
      }
    }
  }
}

.send-loading {
  width: 20px;
  height: 20px;
  border: 2px solid rgb(255, 255, 255);
  background-color: transparent;
  border-top: 3px solid transparent;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

.campaign-loading {
  width: 25px;
  height: 25px;
  border: 3px solid rgb(126, 126, 126);
  background-color: transparent;
  border-top: 3px solid transparent;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

.email-modal-bottom {
  padding: 20px;
  button {
    height: 36px !important;
    min-width: 71px;
    padding: 0px 20px;
  }
  p {
    color: var(--Neutral-90, #404040);
    font-size: 14px;
    font-weight: 400;
  }
}

.customCheckboxLabel,
.customCheckboxIcon {
  cursor: pointer;
  display: inline-block;
  border: 1px solid #28104e;
  border-radius: 3px;
  width: 20px;
  height: 20px;
  position: relative;
}

.customCheckboxTick {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 3px;
}
@media only screen and (max-width: 1440px) {
  .sendEmail-modal {
    width: 57% !important;
  }
}
@media only screen and (max-width: 800px) {
  .sendEmail-modal {
    width: 90% !important;
    .email-modal-header {
      padding: 30px 20px;
      h5 {
        font-size: 18px;
      }
    }
    .preview-modal-content {
      padding: 30px 10px;
      gap: 22px;
      table {
        width: 99%;
        .send-email-thead {
          th {
            padding: 17px 5px;
            font-size: 11px;
            // max-width: 63px !important;
          }
        }
        .send-email-tbody {
          td {
            padding: 17px 5px;
            font-size: 11px;
            // max-width: 63px !important;
          }
        }
      }
    }
  }
  .email-modal-bottom {
    p {
      font-size: 12px;
    }
  }
  .customCheckboxLabel,
  .customCheckboxIcon {
    width: 15px;
    height: 15px;
  }
  .customCheckboxTick {
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 3px;
  }
}
