.setting-subs-main {
  border-radius: 10px;
  background: #f2f2f2;
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.15);
  padding: 24px;

  .wrapper-relative {
    position: relative;

    .locked-feature {
      position: absolute;
      width: 100%;
      height: 100%;
      border-radius: 21.761px;
      background: rgba(255, 255, 255, 0.1);
      overflow: hidden;
      display: flex;
      justify-content: center;
      align-items: center;
      backdrop-filter: blur(10px);
      z-index: 10;

      .lock {
        font-size: 42px;
        color: white;
      }
    }

    .pro-service-card {
      border-radius: 21.761px;
      background: linear-gradient(88deg, #3f1b78 3.64%, #140231 105.81%);
      padding: 28px 80px;
      height: fit-content;
      max-width: 500px;

      .current-credits-card {
        position: relative;
        margin-top: 24px;
        text-align: center;

        .currentcard-content {
          width: 100%;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);

          span {
            font-size: 50px;
            font-weight: 700;

            span {
              font-size: 18px;
              font-weight: 600;
            }
          }
        }
      }

      .other-details-div {
        margin-top: 19px;

        p {
          font-size: 16px;
          font-weight: 600;
        }

        span {
          font-size: 14px;
          font-weight: 600;

          padding-bottom: 11px;
          border-bottom: 1px solid #9a65f2;
          margin-top: 18px;
        }
      }

      button {
        font-size: 18px;
        font-weight: 700;
        width: 192.223px;
        height: 43.522px;
        border-radius: 7.254px;
        background: #5e369c;
        margin-top: 39px;
      }
    }
  }
}

@media(max-width: 769px) {
  .setting-subs-main {
    .wrapper-relative {
      .pro-service-card {
        padding: 40px;
        max-width: 400px;

        .current-credits-card {
          .currentcard-content {
            position: absolute;
            width: 100%;

            label {
              font-size: 12px;
            }

            span {
              font-size: 30px;

              span {
                font-size: 14px;
              }
            }
          }
        }

        .other-details-div {
          margin-top: 15px;

          p {
            font-size: 13px;
          }

          span {
            font-size: 12px;
            font-weight: 300;
            padding-bottom: 5px;
            margin-top: 18px;
          }
        }

        button {
          font-size: 13px;
          padding: 12px;
        }
      }
    }
  }
}

@media(max-width: 576px) {
  .setting-subs-main {
    border-radius: 0px;
    background: #f2f2f2;
    box-shadow: none;
    padding: 0px;

    .wrapper-relative {
      .pro-service-card {
        padding: 20px;
        max-width: 100%;
        border-radius: 10px;
      }
    }
  }
}