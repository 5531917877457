.subs-main {
  padding: 50px 0px;
  gap: 65px;
}
@media only screen and (max-width: 1600px) {
  .subs-main {
    padding: 30px 0px;
    gap: 35px;
  }
}
