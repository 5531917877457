.signup-container {
  // height: 100vh;
  width: 100%;
  overflow: hidden;

  .left-conatiner {
    position: relative;
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 100px;
    height: 100vh;
    flex-direction: column;
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 4px;
    }

    &::-webkit-scrollbar-track {
      background: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background: rgb(197, 197, 197);
      border-radius: 30px;
    }

    .inner-wrapper {
      width: 75%;
      height: 100vh;
      display: flex;
      padding-top: 80px;
      padding-bottom: 20px;
      align-items: center;
      justify-content: center;

      .create-acc-container {
        width: 100%;
        max-width: 600px;
        padding: 50px 40px 80px 40px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 40px;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
        background-color: white;

        .auth-desc {
          font-size: 20px;
          text-align: center;
        }

        // .input-content {
        //   p {
        //   
        //   }

        //   span {
        //     color: rgba(0, 0, 0, 0.4);
        //   
        //   }
        // }

        .w-full {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center !important;
          padding: 20px 0px;

          .nsm7Bb-HzV7m-LgbsSe-bN97Pc-sM5MNb {
            display: flex;
            align-items: center;
            justify-content: center !important;
          }
        }
      }
    }

    .footer-auth {
      width: 100%;
      padding: 20px;
      border-top: 1px solid rgba(0, 0, 0, 0.2);
      text-align: center;
      font-size: 14px;
      align-self: flex-end;
    }
  }

  .right-container {
    width: 50%;
    height: 100vh;
    position: fixed;
    top: 0;
    right: 0;

    img {
      width: 100%;
      height: 100%;
    }
  }
}

.input-password {
  position: relative;
  width: 100%;

  .eye-icon {
    position: absolute;
    top: 55%;
    right: 5%;
  }
}

@media(max-width: 1024px) {
  .signup-container {
    .left-conatiner {
      padding-top: 200px;

      .inner-wrapper {
        width: 90%;

        .create-acc-container {
          .auth-desc {
            font-size: 16px;
          }
        }
      }
    }
  }
}

@media(max-width: 700px) {
  .signup-container {
    .left-conatiner {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;

      .inner-wrapper {
        .create-acc-container {
          width: 100%;
          justify-content: center;
          align-items: center;

          h2 {
            font-size: 32px;
          }

          .w-full {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center !important;
            padding: 20px 0px;

            .nsm7Bb-HzV7m-LgbsSe-bN97Pc-sM5MNb {
              display: flex;
              align-items: center;
              justify-content: center !important;
            }
          }

          .input-content,
          .bottom-btns {
            width: fit-content;
          }
        }
      }
    }

    .right-container {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
      opacity: 0.25;
      filter: blur(25px);
    }
  }
}