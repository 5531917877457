.btn-primary,
.btn-secondary {
  border-radius: 10px;
  font-size: 17px;
  font-weight: 400;

}

.btn-primary {
  background: #28104e;
  color: #fff;
}

.google-btn {
  border: 3px solid #550a9b;
  background-color: #550a9b;
  color: white;
  border-radius: 8px;
  display: flex;
  font-family: 'DM Sans', sans-serif !important;
  justify-content: flex-start;
  gap: 10px;
  align-items: center;
  padding: 0;
  margin: 0;
  overflow: hidden;
  transition: all 0.3s ease-in;

  &:hover {
    background-color: #8433cf;
    transition: all 0.3s ease-in;
    border: 3px solid #8433cf;
  }

  .google-icon {
    width: 60px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    padding: 10px;
  }

  p {
    flex-grow: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 20px;
    font-weight: 600;
  }
}

.button-white {
  background: #fff;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);
  font-size: 16px;
  font-weight: 600;

  border-radius: 41px;

  &:hover {
    background: #28104e;
    color: #f2f2f2 !important;
  }
}

.button-purple-dark {
  border-radius: 41px;
  background: #28104e !important;
  color: var(--Gray-6, #f2f2f2);
  font-size: 14px;
  font-weight: 700;
  padding: 0px 20px;
  text-wrap: nowrap;
}

.button-purple-linear {
  border-radius: 41px;
  border: 1px solid var(--Neural-95, #f2f2f2);
  background: var(--SSS,
      linear-gradient(94deg, #3f1b78 -0.53%, #140231 101.1%));
  color: #f2f2f2;

  font-size: 14px;
  font-weight: 600;
}

.button-purple-transparent {
  width: fit-content;
  white-space: nowrap;
  border-radius: 41px;
  border: 1px solid #28104e;
  color: #181059;

  font-size: 14px;
  font-weight: 700;
  background: transparent;

  &:hover {
    border: 1px solid var(--SSS, #3f1b78);
    background: var(--SSS,
        linear-gradient(94deg, #3f1b78 -0.53%, #140231 101.1%));
    color: #f2f2f2;
  }
}

.button-grey {
  padding: 10px 16px;
  border-radius: 41px;
  border: 1px solid var(--Neural-95, #f2f2f2);
  background: #c2c2c2;
  color: #f2f2f2;
}

@media(max-width:1024px) {
  .google-btn {
    border: 2px solid #550a9b;
    gap: 8px;
    height: 50px;

    &:hover {
      background-color: #8433cf;
      transition: all 0.3s ease-in;
      border: 3px solid #8433cf;
    }

    .google-icon {
      width: 40px;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      // border-radius: 8px;
      background-color: white;
    }

    p {
      font-size: 14px;
      font-weight: 600;
    }
  }
}