.tempalte-modal {
  height: fit-content;
  border-radius: 20px;
  .modal-content {
    padding: 35px 25px 0px 45px;

    .generatebtn {
      margin: auto;
      width: 234px;
      height: 45px;
      font-size: 16px;
      font-weight: 600;
      border-radius: 41px;
      position: relative;
      background: radial-gradient(
        50% 50% at 50% 50%,
        rgba(164, 124, 243, 0.25) 0%,
        rgba(40, 16, 78, 0.25) 100%
      );
      box-shadow: 0px 0px 40px 0px rgba(153, 23, 255, 0.25),
        0px 0px 0.745px 2.982px rgba(255, 255, 255, 0.1),
        0px -2.982px 1.491px 0px rgba(0, 0, 0, 0.25) inset,
        0px 1.491px 0.745px 0px rgba(255, 255, 255, 0.25) inset;
      &::before {
        position: absolute;
        content: "";
        background-image: url(../../../public/assets/images/dashboardimages/modalAIBtn1.png);
        background-size: cover;
        width: 234px;
        height: 45px;
        top: 0;
        right: 0;
        background-repeat: no-repeat;
        pointer-events: none;
      }
    }
    h5 {
    
      margin-top: 3px;
      &:last-child {
          margin-top: 7px;
      }
    }
    .template-cards-wrapper {
      display: grid;
      width: 100%;
      grid-template-columns: repeat(3, 1fr);
      gap: 50px;
      max-height: 500px;
      overflow-y: auto;
      margin-top: 37px;
      margin-bottom: 30px;
    }
    ::-webkit-scrollbar {
      width: 20px;
    }

    ::-webkit-scrollbar-track {
      box-shadow: inset 0 0 7px #dedddd;
      border-radius: 10px;
    }

    ::-webkit-scrollbar-thumb {
      background: #dedddd;
      border-radius: 10px;
    }
    ::-webkit-scrollbar-thumb:hover {
      background: #dedddd;
    }
  }
}
.ant-modal-footer {
  display: none;
}
@media only screen and (max-width: 1350px) {
  .tempalte-modal {
    .modal-content {
      .template-cards-wrapper {
        grid-template-columns: repeat(2, 1fr);
      }
    }
  }
}
@media only screen and (max-width: 800px) {
  .tempalte-modal {
    width: 90% !important;
    .modal-content {
      padding: 30px 20px;
      .template-cards-wrapper {
        grid-template-columns: repeat(1, 1fr);
        gap: 34px;
      }
    }
  }
}
