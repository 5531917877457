.pricing-inner {
  margin-bottom: 25px;
  margin-top: 69px;

  label {
    max-width: 630px;

  }
}

.toogle-div {
  gap: 27px;
  margin-top: 20px;

  button {
    background-color: #28104e;
    border: 0;
    border-radius: 50px;
    cursor: pointer;
    height: 27px;
    position: relative;
    width: 64px;
    -webkit-appearance: none;
    -moz-appearance: none;
  }

  button:focus,
  button:active {
    outline: none;
  }

  button::-moz-focus-inner {
    border: 0;
  }

  .pin {
    background-color: white;
    border-radius: 98px;
    height: 20px;
    left: 4px;
    position: absolute;
    top: 3px;
    width: 20px;
    transition: left ease 0.5s;
  }

  button.on {
    background-color: #522c8d;
  }

  button.on .pin {
    left: 40px;
  }
}

.cards-wrapper {
  display: flex;
  gap: 30px;
  align-items: center;
  justify-content: center;

  .pricingcard-box {
    flex-grow: 1 !important;
    height: 100%;
    width: 100%;
  }
}

@media(max-width: 1452px) {
  .cards-wrapper {
    gap: 20px;
    flex-wrap: wrap;
  }

  .pro {
    margin-bottom: 0px !important;
  }
}

@media(max-width: 800px) {
  .pricing-inner {
    h3 {
      font-size: 32px;
      text-align: center;
    }

    label {
      font-size: 16px;
    }

    .cards-wrapper {
      flex-direction: column;
      width: 100%;
    }
  }

  .toogle-div {
    gap: 16px;
  }

  .isSubscription {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
  }
}