.file-modal {
  min-width: fit-content !important;
  border-radius: 20px;
  background: #fff;
  

  .file-modal-content {
    .file-modal-header {
      background: #f3effd;
      padding: 35px;
      border-top-left-radius: 20px;
      border-top-right-radius: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;

      button {
        color: #555;
      
        font-size: 14px;
        font-weight: 700;
      
        border-radius: 41px;
        border: 1px solid #c2c2c2;
        background: transparent;
        padding: 10px 30px;
      }

      .active {
        background: #28104e;
        color: var(--Gray-6, #f2f2f2);
      }
    }

    .file-content {
      padding: 34px 40px 20px 40px;
      // max-height: 370px;
      height: 100%;

      .google-sheets-content {
        padding-bottom: 50px;
        gap: 10px;

        p {
          color: var(--Neutral-90, #404040);
          font-size: 14px;
          font-weight: 600;
        }

        .url-input {
          width: 100%;
          padding: 16px;
          border-radius: 8px;
          border: 1px solid var(--Neutral-50, #c2c2c2);
          background: var(--Neutral-10, #fff);
        }
      }

      .import-csv-content {
        position: relative;
        margin-bottom: 20px;
        border-radius: 14.972px;
        border: 1.497px dashed rgba(0, 0, 0, 0.25);
        padding: 23.955px 35.932px 23.955px 47.91px;

        img {
          width: 71.864px;
          height: 71.864px;
        }

        .file-icon {
          font-size: 62px;
          margin: 20px 10px;
        }

        .delete-icon {
          position: absolute;
          top: 8px;
          right: 8px;
        }

        button {
          margin-top: 25px;
        }

        #form-file-upload {
          width: 100%;
          text-align: center;
          // position: relative;
          gap: 25px;
        }

        #input-file-upload {
          display: none;
        }

        #drag-file-element {
          position: absolute;
          width: 100%;
          height: 100%;
          border-radius: 1rem;
          top: 0px;
          right: 0px;
          bottom: 0px;
          left: 0px;
        }
      }

      .list-contacts-csv{
        padding: 20px 0px;

        .contacts-bottom-div{
          padding-top: 20px;
        }
      }
    }
  }
}

.ant-modal-content {
  padding: 0px !important;
  border-radius: 16px !important;
}

@media only screen and (max-width: 1440px) {
  .file-modal {
    width: 50% !important;
  }
}

@media only screen and (max-width: 800px) {
  .file-modal {
    width: 90% !important;

    .file-modal-content {
      .file-modal-header {
        button {
          font-size: 12px;
          padding: 10px 30px;
        }
      }

      .file-content {
        padding: 34px 20px 20px 20px;
        height: fit-content;
      }
    }
  }
}


@media only screen and (max-width: 576px) {
  .file-modal-header {
    flex-direction: column;
    button{
      width: 100%;
    }
  }
}