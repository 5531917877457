.start-emailconatainer {
  border-radius: 20px;
  background: #fff;
  // box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);
  overflow: hidden;

  .email-container-top {
    padding: 20px 40px 30px 30px;
    gap: 15px;

    .email-from-div {
      span {
        width: 100%;
      }

      .email-input {
        color: var(--medium-black);
        width: 80%;
      }

      span {
        gap: 30px;

        .from-input {
          margin-left: 16px !important;
        }

        .from-input1 {
          margin-left: 36px !important;

        }

        button {
          width: fit-content;
          height: 31px;
          margin-left: 36px;
        }
      }

      .selected-contact-file {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-left: 30px;

        .del-icon {
          cursor: pointer;
        }
      }
    }
  }

  .text-editor-div {
    // min-height: 350px;
    height: fit-content;
    width: 100%;

    .ql-container {
      border: none;

      .ql-editor,
      .ql-editor.ql-blank::before {
        // color: var(--medium-black) !important;
        color: rgba(0, 0, 0, 0.4);

        font-size: 16px !important;
        font-weight: 400;
        text-align: justify;
        font-style: normal;
      }

      .ql-editor {
        min-height: 250px !important;
        max-height: 300px !important;
      }

      .ql-editor {
        // overflow-y: auto;
      }

      .ql-editor::-webkit-scrollbar {
        width: 6px;
        /* Adjust this value to make the scrollbar thinner */
      }

      .ql-editor::-webkit-scrollbar-track {
        background: #f1f1f100;
        /* Track color */
      }

      .ql-editor::-webkit-scrollbar-thumb {
        background: #bababa;
        /* Thumb color */
        border-radius: 30px;
      }

      .ql-editor p {
        color: var(--medium-black) !important;
        font-family: unset;
        font-size: 16px !important;
        font-weight: 400;
      }

      .ql-editor ul li {
        color: black;
      }
      .ql-editor h1,.ql-editor h2,.ql-editor h3,.ql-editor h4,.ql-editor h5,.ql-editor h6{
        color: black;
      }
    }
  }

  .preview-container {
    width: 100%;
    height: 400px;
    overflow: auto;
    padding: 10px 20px;
    // box-sizing: border-box;
    border-top: 1px solid #ccc;
    background-color: #fff;
    word-wrap: break-word;


    h1 {
      font-size: 2em;
    }

    h2 {
      font-size: 1.5em;
    }

    h3 {
      font-size: 1.17em;
    }

    h4 {
      font-size: 1em;
    }

    h5 {
      font-size: 0.83em;
    }

    h6 {
      font-size: 0.67em;
    }

    p {
      font-size: 16px !important;
    }

    a {
      text-decoration: underline !important;
    }

    &::-webkit-scrollbar {
      width: 4px;
    }

    &::-webkit-scrollbar-track {
      background: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background: rgb(197, 197, 197);
      border-radius: 30px;
    }
  }

}

@media only screen and (max-width: 1540px) {
  .start-emailconatainer {
    .email-container-top {
      padding: 20px 13px 30px 20px;
    }

    .text-editor-div {
      .ql-container {
        // max-height: 200px !important;
      }
    }
  }

  .ql-toolbar.ql-snow .ql-formats {
    margin-right: -4px;
  }

  .ql-snow.ql-toolbar button {
    width: 23px;
  }
}

@media only screen and (max-height: 800px) {
  .start-emailconatainer {
    .text-editor-div {
      // padding-bottom: 135px;
    }
  }
}

@media only screen and (max-width: 800px) {
  .modalclass {
    .email-container-top {
      .email-from-div {
        span {
          input {
            font-size: 12px;
          }
        }

        .email-temp-icons {
          display: none;
        }
      }
    }
  }

  .start-emailconatainer .text-editor-div .ql-container {
    // min-height: 400px;
    // overflow-y: scroll;
    padding-bottom: 20px;
  }

  .start-emailconatainer .text-editor-div {
    padding-bottom: 20px;
  }
}