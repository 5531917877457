.tempalte-modal {
  height: fit-content;
  border-radius: 20px;
  padding: 0px;

  h4 {
    padding: 25px 79px 25px 30px;
  }

  .preview-modal-content {
    padding: 36px 69px 78px 69px;
    background: #f3effd;
    gap: 62px;

    .preview-slider {
      width: 95%;
    }
  }
}

:where(.css-dev-only-do-not-override-42nv3w).ant-modal .ant-modal-content {
  padding: 0px;
}

.slider-inner {
  padding: 10px;
}

.slick-initialized .slick-slide {
  padding: 10px
}

.slick-arrow.slick-prev {
  &::before {
    content: url("../../../public/assets/icons/arrow-left.svg");
    opacity: 1;
    left: -5px;
    top: 15px;
    position: absolute;
  }
}

.slick-arrow.slick-next {
  &::before {
    content: url("../../../public/assets/icons/arrow-right.svg");
    position: absolute;
    top: 15px;
    left: -12px;
    opacity: 1;
  }
}

.slick-prev.slick-disabled,
.slick-next.slick-disabled {
  &::before {
    opacity: 0.25 !important;
    cursor: not-allowed !important;
  }
}

.tempalte-send-modal {
  height: fit-content;
  max-width: 450px;
  border-radius: 20px;
  padding: 0px;

  .send-modal {
    width: 100%;
    padding: 0px 8px 12px 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .concense {
      width: 90%;
      display: flex;
      justify-content: center;
      align-items: flex-start;
      gap: 8px;

      .ant-checkbox-wrapper {
        height: 15px;
        margin-top: 2px;

        .ant-checkbox {
          width: 15px;
          height: 15px;
        }

        .ant-checkbox-checked .ant-checkbox-inner:after {
          border: 2px solid #fff;
          border-top: 0;
          border-inline-start: 0;
          content: "";
          left: 3px;
          top: 7px;
          transform: rotate(45deg) scale(1) translate(-50%, -50%);
        }
      }

      span {
        font-size: 14px;
      }
    }

    .preview-send-modal-content {
      padding: 40px;
    }

    button {
      width: fit-content;
      padding: 12px 30px;
    }
  }
}

.sendEmail-modal .preview-modal-content table .send-email-tbody td {
  text-wrap: nowrap;
}

@media only screen and (max-height: 800px) {
  .tempalte-modal {
    h4 {
      padding: 20px 79px 20px 30px;
    }

    .preview-modal-content {
      padding: 20px 69px 28px 69px;
      gap: 27px;

      .preview-slider {
        width: 98%;
      }
    }
  }

  .tempalte-send-modal {
    width: 45% !important;
  }
}

@media only screen and (max-width: 800px) {
  .tempalte-modal {
    width: 90% !important;

    .preview-modal-content {
      padding: 30px 20px;
      gap: 32px;
    }
  }

  .slider-inner {
    padding: 0px !important;
  }

  .tempalte-send-modal {
    width: 90% !important;

    .preview-send-modal-content {
      padding: 38px;
      flex-direction: column;
    }

    button {
      padding: 12px 20px;
    }
  }
}