.forget-container {
  height: fit-content;
  padding: 53px 53px 53px 115px !important;
  position: relative;
  overflow: hidden;
  min-height: 100vh;
  .left-conatiner {
    width: 35%;
    gap: 70px;
    .account-wrapper {
      gap: 176px;
    }
    .email-container {
      width: fit-content;
      gap: 64px;
      .input-content {
        max-width: 400px;
        p {
        
        }
        span {
          color: rgba(0, 0, 0, 0.4);
        
        }
      }
    }
  }
  .right-container {
    width: 55%;
    img {
      width: 100%;
      height: 100%;
    }
  }
}
@media only screen and (max-width: 1250px) {
  .forget-container {
    padding: 53px 53px 53px 53px !important;
    .right-container {
      width: 59%;
    }
  }
}
@media only screen and (max-width: 700px) {
  .forget-container {
    padding: 40px 20px !important;
    min-height: 100vh;
    .left-conatiner {
      width: 100%;
      .account-wrapper {
        width: 100%;
        .email-container {
          width: 100%;
          justify-content: center;
          align-items: center;
          h2 {
            font-size: 32px;
          }
          .input-content {
            width: fit-content;
          }
        }
        a {
          span {
            h5 {
              font-size: 20px;
            }
            .arrow-left {
              width: 18px;
              height: 18px;
            }
          }
        }
      }
    }
    .right-container {
      display: none;
    }
  }
}
