.select-template {
  width: 100%;
  padding: 10px 0px;
  text-align: center;

  .button-select {
    padding: 10px 20px;
    border-radius: 41px;
    border: 1px solid var(--Neural-95, #f2f2f2);
    background: var(--SSS,
        linear-gradient(94deg, #3f1b78 -0.53%, #140231 101.1%));
    color: #f2f2f2;
    font-size: 14px;
    font-weight: 600;

    span {
      font-weight: 700 !important;
      padding-right: 3px;
    }
  }
}

.template-card {
  max-width: 360px;
  max-height: 376px;
  position: relative;
  overflow: hidden;

  .temp-card-top {
    border-top-left-radius: 8.911px;
    border-top-right-radius: 8.911px;
    border: 2px solid #f3f2fc;
    border-bottom: 0px;
    background: #f3f2fc;
    padding: 18px 22px 0px 22px;
    max-height: 318px;
    overflow: hidden;
    transition: 0.7s ease-in-out;

    .top-inner {
      border-top-left-radius: 9.247px;
      border-top-right-radius: 9.247px;
      background: #fff;
      padding: 28px 28px 28px 28px;
      height: 250px;

      img {
        width: 100%;
      }

      p {
        color: #7d7d7d;
        margin-top: 8px;
      }
    }
  }

  .temp-card-bottom {
    border-radius: 0px 0px 8.911px 8.911px;
    border-top: 1px solid rgba(0, 0, 0, 0.3);
    background: #f3f3f3;
    padding: 19px;
    transition: 0.7s ease-in-out;

    label {
      color: #2b2b2b;

      font-size: 16px;
      font-weight: 400;
    }
  }

  .action-ribbon {
    position: absolute;
    bottom: 10px;
    right: 10px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 20px;
    font-size: 28px;
    color: white;
    z-index: 30;
    opacity: 0;
    transform: translateY(20px);
    transition: 0.3s ease-in-out;

    .item {
      cursor: pointer;
      transition: 0.3s ease-in-out;

      &:hover {
        transform: scale(1.25);
      }
    }
  }

  &:hover {
    border-radius: 8.911px;

    .temp-card-bottom {
      background: #c8c2ff;
    }

    &:after {
      position: absolute;
      top: 30%;
      left: 0;
      width: 100%;
      height: 100%;
      content: "";
      background: linear-gradient(180deg,
          rgba(44, 39, 127, 0) 0%,
          rgba(79, 70, 229, 0.74) 100%);
      transition: 0.7s ease-in-out;
    }

    .action-ribbon {
      opacity: 1;
      transform: translateY(0px);
    }
  }

  .hover-temp-card {
    position: absolute;
    bottom: -50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: bottom 0.7s ease-in-out;

    .button-select {
      width: 200px;
      height: 38px;
    }
  }
}

.active-card {
  border-radius: 8.911px;

  .temp-card-bottom {
    background: #c8c2ff;
  }

  &:after {
    position: absolute;
    top: 30%;
    left: 0;
    width: 100%;
    height: 100%;
    content: "";
    background: linear-gradient(180deg,
        rgba(44, 39, 127, 0) 0%,
        rgba(79, 70, 229, 0.74) 100%);
    transition: 0.7s ease-in-out;
  }
}

@media only screen and (max-height: 600px) {
  .template-card {
    max-height: 376px;
  }
}