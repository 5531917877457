.credit-modal {
  height: fit-content;
  padding: 0px;

  .modal-content {
    background: #fff;
    border-radius: 24px !important;

    .purple-header {
      background: #2a1151;
      height: 103px;
      border-radius: 15px 15px 0px 0px !important;
    }

    .bottom-content {
      padding: 20px;
      margin-top: -104px !important;

      h4 {
        margin-top: 15px;
        max-width: 321px;
      }

      p {
        margin-top: 10px;
        max-width: 326px;
      }

      .modal-buttons {
        button {
          border-radius: 41px;
          border: 1px solid #28104e;
          width: 124px;
          height: 43px;

          &:first-child {
            background: transparent;
            font-size: 14px;
            font-weight: 600;
            transition: 0.3s ease-in-out;

            &:hover {
              background: #28104e;
              color: #fff;
            }
          }

          &:last-child {
            background: #28104e;
            font-size: 14px;
            font-weight: 700;
            color: var(--Gray-6, #f2f2f2);

            &:hover {
              background: linear-gradient(94deg,
                  #3f1b78 39.29%,
                  #140231 101.1%);
            }
          }
        }

        .del-acc-btn {
          width: 161px;
        }
      }
    }
  }

  .ant-modal-close {
    display: none;
  }
}

@media only screen and (max-width: 1500px) {
  .credit-modal {
    width: 35% !important;
  }
}

@media only screen and (max-width: 1300px) {
  .credit-modal {
    min-width: 400px !important;
  }
}

@media only screen and (max-width: 600px) {
  .credit-modal {
    min-width: 300px !important;
    width: 80% !important;

    .modal-content {
      .bottom-content {
        h4 {
          max-width: 296px;
          text-align: center;
        }

        p {
          max-width: 296px;
        }

        .modal-buttons {
          .del-acc-btn {
            width: 124px;
          }
        }
      }
    }
  }
}