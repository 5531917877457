.settings-main-conatainer {
  padding: 35px 30px;
  .setting-tab-switcher {
    border-radius: 41px;
    background: transparent;
    border: 1px solid var(--dark-purple);
    max-width: 520px;
    label {
      color: var(--dark-purple);
      padding: 16px;
      width: 50%;
      font-weight: 600;
    }
    .active {
      background: var(--dark-purple);
      border-radius: 41px;
      color: var(--white);
    }
  }
}
@media only screen and (max-width: 700px) {
  .settings-main-conatainer {
    padding: 30px 20px;
    .setting-tab-switcher {
      label {
        padding: 10px;
      }
    }
  }
}
