.create-template-card {
  background: var(--Neutral-10, #fff);
  box-shadow: 0 0 16px rgba(0, 0, 0, 0.08);
  border-radius: 10px;
  padding: 20px;
}

.variation-card {
  max-width: 879px !important;
  width: 100% !important;
  border-radius: 8px;
  margin: 10px 0px;
  background: var(--Neutral-10, #fff);
  position: relative;
  display: flex;
  align-items: flex-start;
}

.variation-card-relative {
  position: relative;
  width: 100% !important;

  textarea {
    color: black;

    padding: 16px;
    font-size: 16px;
    outline: none;
    font-weight: 400;
    background-color: transparent;
    border: 1px solid var(--Neutral-50, #c2c2c2);
    border-radius: 8px;
    width: 100%;
    resize: vertical;
    overflow: hidden;

    &::placeholder {
      color: var(--Neutral-50, #c2c2c2);
    }
  }
}

.btn-generate-between {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px 0;
}

.btn-generate-between button {
  width: 40px;
  height: 40px;
  border-radius: 30px;
  color: white;
  font-size: 16px;
  background: linear-gradient(94deg, #3f1b78 -0.53%, #140231 101.1%);
  transition: all 0.3s ease-in-out;
  overflow: hidden;
  z-index: 0;
  filter: drop-shadow(0 0 0.6rem rgb(58, 21, 118));
  position: relative;
}

.btn-generate-between button::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 30px;
  background: linear-gradient(94deg, #7d38ed -0.53%, #3b088d 101.1%);
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  z-index: -1;
}

.btn-generate-between button:hover::before {
  opacity: 1;
}

::-webkit-scrollbar {
  width: 12px;
  cursor: pointer;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 7px #dedddd;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: #dedddd;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: #dedddd;
}

.text-error-compaign {
  font-size: 12px;
  color: red;
  padding: 10px 0px;
  margin-top: -10px;
}

@media(max-width: 769px) {
  .variation-card {
    flex-direction: column;
    align-items: center;
  }
}