.flare {
  position: absolute;
  bottom: -25%;
  right: -15%;
  width: 584px;
  height: 584px;
  border-radius: 584px;
  opacity: 0.2;
  background: #8762e9;
  z-index: -1;
  filter: blur(200.35000610351562px);
}
.dark-flare {
  position: absolute;
  bottom: 0;
  right: -5%;
  width: 584px;
  height: 584px;
  border-radius: 946px;
  opacity: 0.4;
  background: #8762e9;
  z-index: -1;
  filter: blur(200.35000610351562px);
}
