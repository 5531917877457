table {
  box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  width: 100%;

  .send-email-thead {
    border-radius: 10px;

    th {
      padding: 17px 20px;
      background: rgba(135, 98, 233, 0.2);
      box-shadow: 0px -1px 0px 0px #f0f0f0 inset;
      color: var(--Neutral-100, #0a0a0a);
      font-size: 16px;
      font-weight: 600;
      min-width: 133px;

      &:first-child {
        border-top-left-radius: 10px;
      }

      &:last-child {
        border-top-right-radius: 10px;
        width: 146px;
      }
    }
  }

  .send-email-tbody {
    cursor: pointer;

    td {
      padding: 17px 20px;
      border-radius: 5px 5px 0px 0px;
      color: var(--Neutral-90, #404040);

      font-size: 16px;
      font-weight: 600;
      border-bottom: 1px solid #ededed;
      min-width: 133px;

      button {
        padding: 6px 16px;
        border-radius: 8px;
        border: 1px solid var(--dark-purple);
        background: var(--Neutral-10, #fff);
        box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.12);
        color: var(--dark-purple);
        font-size: 16px;
        font-weight: 600;
        transition: border 0.4s ease-in-out;

        &:hover {
          border: 1px solid #dcdcdd;
        }
      }
    }
  }
}

@media only screen and (max-width: 1200px) {
  table {
    .send-email-thead {
      th {
        font-size: 14px;
        min-width: 93px;
      }
    }

    .send-email-tbody {
      td {
        font-size: 14px;
        min-width: 133px;

        button {
          padding: 6px 16px;
          font-size: 14px;
        }
      }
    }
  }
}

@media only screen and (max-width: 700px) {
  .list-tabl-div {
    overflow: auto;

    &::-webkit-scrollbar {
      height: 4px;
    }

    &::-webkit-scrollbar-track {
      background: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background: rgb(197, 197, 197);
      border-radius: 30px;
    }

    table {
      width: 100%;

      .send-email-thead {
        th {
          padding: 10px 5px;
          font-size: 12px;
          min-width: 143px;
        }
      }

      .send-email-tbody {
        td {
          padding: 10px 5px;
          font-size: 12px;
          min-width: 143px;

          button {
            padding: 6px 12px;
            font-size: 12px;

            &:hover {
              border: 1px solid #dcdcdd;
            }
          }
        }
      }
    }
  }

  .contacts-bottom-div {
    justify-content: right;

    label {
      font-size: 12px;
      // display: none;
    }
  }
}

.ant-modal-close:hover {
  color: #c2c2c2;

  .excelmodal-content {
    padding-top: 50px;
    height: 80vh;
    color: white;
  }
}