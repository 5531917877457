.template-main-container {
  margin: 55px 20px 20px 45px;
  border-radius: 20px;
  background: #fff;
  padding: 35px 35px 35px 45px;
  gap: 25px;
  width: 83%;

  .generate-btn {
    margin: auto;
    width: 234px;
    height: 45px;
    font-size: 16px;
    font-weight: 600;
    border-radius: 41px;
    position: relative;
    background: radial-gradient(50% 50% at 50% 50%,
        rgba(164, 124, 243, 0.25) 0%,
        rgba(40, 16, 78, 0.25) 100%);
    box-shadow: 0px 0px 40px 0px rgba(153, 23, 255, 0.25),
      0px 0px 0.745px 2.982px rgba(255, 255, 255, 0.1),
      0px -2.982px 1.491px 0px rgba(0, 0, 0, 0.25) inset,
      0px 1.491px 0.745px 0px rgba(255, 255, 255, 0.25) inset;

    &::before {
      position: absolute;
      content: "";
      background-image: url(../../../../../public/assets/images/dashboardimages/modalAIBtn1.png);
      background-size: cover;
      width: 234px;
      height: 45px;
      top: 0;
      right: 0;
      background-repeat: no-repeat;
      pointer-events: none;
    }
  }

  .template-cards-wrapper {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 50px;
    // max-height: 622px;
    // overflow-y: scroll;
  }

  ::-webkit-scrollbar {
    width: 20px;
  }

  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 7px #dedddd;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb {
    background: #dedddd;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #dedddd;
  }
}

@media only screen and (max-width: 1600px) {
  .template-main-container {
    margin: 55px 20px 20px 20px;
    width: 90%;
    padding: 35px 30px;

    .template-cards-wrapper {
      gap: 18px;
    }
  }
}

@media only screen and (max-width: 1400px) {
  .template-main-container {
    width: 96%;

    .template-cards-wrapper {
      gap: 9px;
    }
  }
}

@media only screen and (max-width: 1280px) {
  .template-main-container {
    width: 88%;
    padding: 35px 35px 35px 45px;

    .template-cards-wrapper {
      grid-template-columns: repeat(2, 1fr);
      gap: 44px;
    }
  }
}

@media only screen and (max-width: 700px) {
  .template-main-container {
    margin: 30px 20px;
    padding: 35px 20px;
    width: 100 !important;

    .template-cards-wrapper {
      grid-template-columns: repeat(1, 1fr);
    }
  }
}