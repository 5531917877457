.setting-switcher {
  .switcher-tab {
    border: 1px solid #28104e;
    border-radius: 7.05px;
    .borderradius {
      border-radius: 41px;
      height: 48px;
    }

    span {
      cursor: pointer;
      width: 50%;
      height: 43.52px;
      gap: 10px;
      border-radius: 6.05px;
      p {
        color: var(--dark-purple);
      
      }
    }

    .active {
      background: var(--dark-purple);
      p,
      .switcher-icon {
        color: #f2f2f2 !important;
      }
    }
  }
  .borderradius {
    border-radius: 41px;
  }
  .content-container,
  .setting-container {
    padding: 19px;

    .content-tab,
    .setting-tab {
      padding-bottom: 20px;
      margin-bottom: 20px;
      border-bottom: 1px solid #7d7d7d;
      .content-tab-left-icons {
        gap: 9px;
      }
    }
    .iconsize {
      width: 24px;
      height: 24px;
    }
    .color-div {
      .color-right-div {
        padding: 4px 35px 4px 4px;
        border-radius: 5px;
        background: #fff;
        .input-color {
          padding: 0px;
          width: 24px;
          height: 24px;
          border-radius: 4.533px !important;
          cursor: pointer;
          border: none;
          outline: none;
        }
        input[type="color"]::-webkit-color-swatch {
          border-radius: 4.533px;
          border-color: var(--white);
        }

        p {
          width: 50px;
        }
      }
    }
    .font-div {
      select {
        color: #7d7d7d;
      
        padding: 4px 35px 4px 4px;
        border: none;
        width: 121px;
        border-radius: 5px;
        background: #fff;
        height: 34px;
        &:focus {
          outline: none;
        }
      }
    }
    .icons-div {
      margin-top: 21px;
    }
    .progress {
      height: 2px;
      width: 100%;
      margin-top: 13px;
      margin-bottom: 30px;
    }

    .progress-done {
      position: relative;
      background: #bff2ff;
      border-radius: 20px;
      height: 100%;
      opacity: 0;
      transition: 1s ease 0.3s;
    }
  }
  .preview-btn {
    &:hover {
      color: #f2f2f2 !important;
      border-radius: 8px !important;
      border: 1.76px solid var(--SSS, #3f1b78) !important;
      background: var(
        --SSS,
        linear-gradient(94deg, #3f1b78 -0.53%, #140231 101.1%)
      ) !important;
      transition: 0.5s ease-in-out !important;
    }
  }
}
@media only screen and (max-width: 1320px) {
  .setting-switcher {
    .switcher-tab {
      span {
        gap: 2px;
      }
    }
    .content-container,
    .setting-container {
      padding: 19px 8px;
      .content-tab,
      .setting-tab {
        .content-tab-left-icons {
          gap: 4px;
        }
      }
      // .color-div {
      //   .color-right-div {
      //     padding: 4px;
      //   }
      // }
    }
  }
}
