.create-campaign-main {
  padding: 50px 20px 50px 0px;

  .text-variation-container {
    border-radius: 20px;
    border: 1px solid var(--Border-Gray, #e6e6e6);
    background: var(--neutral-02-white, #fff);
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);
    padding: 24px;
    max-width: 1076px;
    margin-top: 100px !important;

    .text-variation-inner {
      padding: 24px;

      .variation-heading {
        p {
          width: 24px;
          height: 24px;
          border-radius: 12px;
          background: #181059;
        }

        label {
          color: var(--Neutral-90, #404040);
        
          font-size: 14px;
          font-weight: 600;
          line-height: 20px;
          letter-spacing: -0.084px;
        }
      }

      .v-card {
        margin-top: 15px;
        align-items: start;

        .variation-card-wrapper {
          position: relative;
          display: grid;
          grid-template-columns: repeat(1, 1fr);
          row-gap: 15px;
          column-gap: 21px;

          .relative {
            position: relative;

            .absolute {
              position: absolute;
              top: 10px;
              left: 0;
            }

            .element-loading {
              width: 100%;
              height: 100%;
              top: 0 !important;
              background-color: rgba(231, 230, 230, 0.5);
              display: flex;
              align-items: center;
              justify-content: center;
            }
          }

          .selected {
            border: 1px solid #28104e;
          }

          .card-lock {
            position: absolute;
            border-radius: 8px;
            border: 1px solid rgba(255, 255, 255, 0.01);
            opacity: 0.8;
            background: rgba(207, 207, 207, 0.39);
            backdrop-filter: blur(15.300000190734863px);
            // max-width: 879px !important;
            width: 100% !important;
            padding: 16px;
            height: 101px !important;
          }
        }

        .plus_template {
          margin-top: 20px;
        }
      }

      .own-temp-div {
        label {
          color: var(--Neutral-90, #404040);
        
          font-size: 14px;
          font-weight: 600;
          line-height: 20px;
          letter-spacing: -0.084px;
        }

        textarea {
          height: 161px;
          color: black;
        
          font-size: 16px;
          padding: 16px;
          outline: none;
          border-radius: 8px;
          border: 2px dashed var(--Neutral-50, #c2c2c2);
          background: var(--Neutral-10, #fff);
          font-weight: 400;
          width: 100%;

          &::placeholder {
            color: var(--Neutral-50, #c2c2c2);
          }
        }

        textarea::-webkit-scrollbar {
          width: 6px !important;
        }

        textarea::-webkit-scrollbar-track {
          background: #f1f1f100;
        }

        textarea::-webkit-scrollbar-thumb {
          background: #bababa;
          border-radius: 30px;
        }
      }

      .error-message {
        font-size: 12px;
        color: red;
        float: right;
      }

      .variation-bottom {
        margin-top: 41px;
        justify-content: right;

        button {
        
          font-size: 14px;
          font-weight: 600;
          height: 45px;

          &:first-child {
            padding: 10px 16px;
            border-radius: 41px;
            border: 1px solid var(--Neural-95, #f2f2f2);
            background: #c2c2c2;
            color: #f2f2f2;
          }

          &:nth-child(2) {
            width: 91px;
          }

          &:last-child {
            color: #1e1e1e;
            border-radius: 41px;
            border: 1.95px solid #f3f3f3;
            background: radial-gradient(50% 50% at 50% 50%,
                rgba(164, 124, 243, 0.25) 0%,
                rgba(40, 16, 78, 0.25) 100%);
            box-shadow: 0px 0px 40px 0px rgba(153, 23, 255, 0.25),
              0px 0px 0.745px 2.982px rgba(255, 255, 255, 0.1),
              0px -2.982px 1.491px 0px rgba(0, 0, 0, 0.25) inset,
              0px 1.491px 0.745px 0px rgba(255, 255, 255, 0.25) inset;
            width: 176.436px;
            height: 47px;
            position: relative;

            &::before {
              position: absolute;
              content: "";
              background-image: url(../../../../../../public/assets/images/dashboardimages/createbtnBg1.png);
              background-size: cover;
              width: 176.436px;
              height: 45px;
              top: 0;
              right: 0;
              background-repeat: no-repeat;
              pointer-events: none;
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-height: 800px) {
  .create-campaign-main {
    .text-variation-container {
      margin-top: 70px !important;
    }
  }
}

@media only screen and (max-width: 700px) {
  .create-campaign-main {
    padding: 30px 20px;

    .text-variation-container {
      max-width: fit-content;
      margin-top: 40px !important;

      h4 {
        font-size: 18px;
      }

      .text-variation-inner {
        padding: 24px 10px;

        .variation-heading {
          p {
            width: 20px;
            height: 20px;
          }
        }

        .variation-card-wrapper {
          grid-template-columns: repeat(1, 1fr);
        }

        .variation-bottom {
          justify-content: center;
          gap: 5px !important;
          flex-wrap: wrap;
        }
      }
    }
  }

  .v-card {
    flex-direction: column;
    gap: 10px !important;
  }
}