.ant-modal-root .ant-modal-mask {
    background-color: #020003a4;
}

.free-trail-banner {
    display: flex;
    justify-content: center;
    width: fit-content !important;

    .ant-modal-close {
        color: rgb(0, 0, 0) !important;
        opacity: 0.7 !important;

        &:hover {
            background-color: transparent !important;
        }
    }

    .ant-modal-content {
        background-color: transparent !important;
        width: 600px !important;
        overflow: hidden;

        .ant-modal-body {
            .free_trail {
                width: 100%;
                position: relative;
                overflow: hidden;
                height: 400px !important;
                // background: linear-gradient(to top, #f4e7ff79, #f4e7ff38), url('../../../public/assets/images/banner.jpg');
                background-color: white;
                border-radius: 25px;
                background-size: cover;
                display: flex;
                flex-direction: column;
                gap: 20px;
                justify-content: center;
                align-items: center;

                // .banner {
                //     background: linear-gradient(to top, #f4e7ff, #f4e7ffc5, #f4e7ff38), url('../../../public/assets/images/banner.jpg');
                //     background: url('../../../public/assets/images/banner.jpg');
                //     background-size: cover;
                //     width: 100%;
                //     height: 250px;
                //     object-fit: cover;
                //     display: flex;
                //     align-items: flex-end;
                //     padding: 20px 40px;

                //     h2 {
                //         color: #2a1151;
                //         font-size: 42px;
                //         font-weight: 500;
                //         margin-bottom: -50px;
                //         opacity: 0.75;
                //     }
                // }

                // .content-box {

                //     display: flex;
                //     flex-direction: column;
                //     gap: 20px;
                //     justify-content: center;
                //     align-items: center;
                //     margin-top: 60px;
                //     padding-bottom: 30px;

                .shade {
                    background-color: #c200fd;
                    width: 160px;
                    height: 160px;
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    left: -20px;
                    filter: blur(140px);
                }

                .shade2 {
                    background-color: #270047;
                    width: 160px;
                    height: 160px;
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    right: -20px;
                    filter: blur(140px);
                }

                button {
                    background: var(--primary-button-background,
                            linear-gradient(278deg,
                                rgba(241, 185, 255, 0) 17.83%,
                                rgba(241, 185, 255, 0.21) 42%,
                                rgba(241, 185, 255, 0.21) 60.93%,
                                rgba(241, 185, 255, 0) 81.89%),
                            linear-gradient(109deg,
                                rgba(111, 134, 255, 0.4) 0.67%,
                                rgba(111, 134, 255, 0) 141.36%),
                            linear-gradient(292deg,
                                rgba(111, 134, 255, 0.4) -67.17%,
                                rgba(111, 134, 255, 0) 72.61%),
                            #180438);
                    background-position: 0 0;
                    background-size: 200% 100%;
                    color: #fff;
                    cursor: pointer;
                    font-size: 18px;
                    font-weight: 300;
                    padding: 16px 36px;
                    text-align: center;
                    border-radius: 30px;
                    transition: background-position 0.5s cubic-bezier(0.77, 0.01, 0.16, 0.99);

                    &:hover {
                        transition: background-position 0.5s cubic-bezier(0.77, 0.01, 0.16, 0.99);
                        background-position: 100% 0;
                    }
                }

                .maininner-new {
                    cursor: pointer;
                    z-index: 5;
                    width: 280px;
                    height: 60px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    background: linear-gradient(90deg,
                            #ed25c1,
                            #a74fff,
                            #f096ff,
                            #ffffff,
                            #ffffff,
                            #ff96e2,
                            #874fff,
                            #4d25ed);
                    overflow: hidden;
                    cursor: pointer;
                    border-radius: 100px;
                    padding: 0px 4px;
                    transition: 0.5s ease-in-out;

                    .inner {
                        display: flex;
                        width: 100%;
                        height: 54px;
                        padding: 25px 10px !important;
                        justify-content: center;
                        align-items: center;
                        gap: 10px;
                        border-radius: 10px;
                        background: linear-gradient(94deg, #652eac -0.53%, #1f0231 101.1%);
                        border-radius: 100px;
                        transition: 0.5s ease-in-out;

                        &:hover {
                            background: #f2f2f2;
                            transition: 0.5s ease-in-out;

                            span {
                                color: #2b2b2b;
                            }
                        }

                        span {
                            color: #fff;
                            font-family: 'DM Sans', sans-serif;
                            font-size: 20px;
                            font-style: normal;
                            font-weight: 600;
                            opacity: 1 !important;
                            transition: 0.3s ease-in-out;
                            line-height: normal;
                        }
                    }
                }

                .or-section {
                    width: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    gap: 10px;

                    .bar {
                        width: 40px;
                        border-top: 1px solid black;
                        height: 1px;
                        opacity: 0.4;
                    }

                    span {
                        text-align: center;
                        // color: rgb(0, 0, 0);
                        color: rgb(0, 0, 0);
                        font-weight: 600;
                        font-size: 18px;
                        // opacity: 0.4;
                    }
                }

                a {
                    text-align: center;
                    font-size: 20px;
                    text-decoration: underline;
                    color: #4900be;

                    &:hover {
                        color: #751fff;
                    }
                }

                // }
            }
        }
    }
}

@media (max-width: 769px) {
    .free-trail-banner {
        width: 90% !important;

        .ant-modal-content {
            width: 100% !important;

            .ant-modal-body {
                .free_trail {
                    .banner {
                        h2 {
                            font-size: 36px;
                        }
                    }
                }
            }
        }
    }
}