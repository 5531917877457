.sidebar-main-container {
  width: 300px;
  position: fixed;
  border-radius: 0px 20px 20px 0px;
  background: linear-gradient(185deg, #28104e 0%, #481e8b 100%);
  height: calc(100vh - 30px);
  padding: 20px 2px;
  padding-left: 0px;
  transition: width 0.3s ease-in-out;
  z-index: 40;

  .scrollable {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    height: 100%;
    padding: 2px;

    .sidebar-logo-container {
      padding: 12px 0px 29px 0px;
      position: relative;
      height: 140px;
      filter: drop-shadow(0 0 1.2rem rgb(22, 0, 27));

      .logo {
        margin: auto;

      }
    }

    .sidebar-options-container {
      padding: 15px 0px;
      height: 100%;

      ul {
        height: 100%;

        li {
          .dashLinks {
            border-radius: 500px;
            padding: 15px 20px 15px 45px;
            cursor: pointer;
            color: #fff;
            font-size: 16px;
            font-weight: 400;
            margin-bottom: 20px;
                  display: flex;
            align-items: center;

            .sidebar-icons {
              margin-right: 10px;
              color: #fff;
            }

            &.active {
              border-radius: 0px 8px 8px 0px;
              background: #f3f2fc;
              color: #2b2e48;
              font-weight: 700;
              letter-spacing: 0.16px;
              padding: 15px 20px 15px 45px;

              .sidebar-icons {
                color: #2b2e48;
              }
            }
          }
        }
      }
    }

    &::-webkit-scrollbar {
      width: 5px;
    }

    &::-webkit-scrollbar-track {
      background: rgba(241, 241, 241, 0);
    }

    &::-webkit-scrollbar-thumb {
      background: transparent;
      border-radius: 30px;
    }

    &:hover {
      &::-webkit-scrollbar-thumb {
        background: #9858ff;
      }
    }
  }
}

.logout-modal {
  .modal-content {
    min-width: 300px;
    background: #fff;
    border-radius: 24px !important;
    padding-bottom: 30px;

    .purple-header {
      background: #2a1151;
      height: 103px;
      width: 100%;
      border-radius: 15px 15px 0px 0px !important;
    }

    .bottom-content {
      margin-top: -104px !important;

      .img-modal-container {
        height: 225px;
        margin-bottom: -40px;

        img {
          height: 100%;
        }
        .remove-template{
          padding: 50px;
          margin-top: -20px;
        }
      }

      h4 {
        margin-top: 15px;
        max-width: 321px;
        text-align: center;
      }

      p {
        margin-top: 10px;
        max-width: 326px;
      }

      .modal-buttons {
        button {
          border-radius: 41px;
          border: 1px solid #28104e;
          width: 124px;
          height: 43px;

          &:first-child {
            background: transparent;
            font-size: 14px;
            font-weight: 600;
            transition: 0.3s ease-in-out;

            &:hover {
              background: #28104e;
              color: #fff;
            }
          }

          &:last-child {
            background: #28104e;
            font-size: 14px;
            font-weight: 700;
            color: var(--Gray-6, #f2f2f2);

            &:hover {
              background: linear-gradient(94deg,
                  #3f1b78 39.29%,
                  #140231 101.1%);
            }
          }
        }

        .del-acc-btn {
          width: 161px;
        }
      }
    }
  }

  .ant-modal-close {
    display: none;
  }
}

@media only screen and (max-height: 800px) {
  .sidebar-main-container {
    width: 250px;

    .sidebar-logo-container {
      padding: 12px 0px 12px 0px;
    }

    .sidebar-options-container {
      ul {
        li {
          .dashLinks {
            padding: 8px 20px 8px 45px;
            font-size: 14px;

            .sidebar-icons {
              width: 20px;
              height: 20px;
            }

            span {
              margin-top: 0px;
            }

            &.active {
              padding: 10px 20px 10px 45px;
            }
          }
        }
      }
    }
  }

  .bottom-options {
    margin-top: 0px !important;
  }
}

@media only screen and (max-width: 1023px) {
  .sidebar-main-container {
    display: none;
  }

  .mobile-menu-drawer {
    background: none !important;
    padding: 0;
  }

  .sidebar-container {
    width: 280px;
    position: fixed;
    border-radius: 0px 20px 20px 0px;
    background: linear-gradient(185deg, #28104e 0%, #481e8b 100%);
    height: 100vh;
    padding: 10px 2px;
    transition: width 0.3s ease-in-out;
    z-index: 40;

    .scrollable {
      display: flex;
      flex-direction: column;
      overflow-y: auto;
      height: 100%;
      padding: 2px;

      .sidebar-logo-container {
        padding: 12px 0px 29px 0px;
        position: relative;
        height: 140px;

        .logo {
          margin: auto;
        }
      }

      .sidebar-options-container {
        padding: 15px 0px;
        height: 100%;

        ul {
          height: 100%;

          li {
            .dashLinks {
              border-radius: 500px;
              padding: 10px 20px 10px 45px;
              color: #fff;
              font-size: 16px;
              font-weight: 400;
              margin-bottom: 20px;
            

              .sidebar-icons {
                margin-right: 10px;
                color: #fff;
              }

              span {
                margin-top: 2px;
              }

              &.active {
                border-radius: 0px 8px 8px 0px;
                background: #f3f2fc;
                color: #2b2e48;
                font-weight: 700;
                letter-spacing: 0.16px;
                padding: 10px 20px 10px 45px;

                .sidebar-icons {
                  color: #2b2e48;
                }
              }
            }
          }
        }
      }

      &::-webkit-scrollbar {
        width: 5px;
      }

      &::-webkit-scrollbar-track {
        background: rgba(241, 241, 241, 0);
      }

      &::-webkit-scrollbar-thumb {
        background: transparent;
        border-radius: 30px;
      }

      &:hover {
        &::-webkit-scrollbar-thumb {
          background: #9858ff;
        }
      }
    }

    .side-bar-arrow {
      position: absolute;
      right: -8%;
      top: 100px;
    }
  }

  :where(.css-dev-only-do-not-override-42nv3w).ant-drawer .ant-drawer-body,
  .ant-drawer,
  .ant-drawer-body {
    padding: 0px !important;
  }

  :where(.css-dev-only-do-not-override-42nv3w).ant-drawer-left>.ant-drawer-content-wrapper,
  .ant-drawer-content-wrapper {
    box-shadow: none !important;
  }
}