.template-detail-main-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
  height: calc(100vh - 90px);

  .w-75 {
    width: 75%;
  }



  .preview-emails-container {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;

    .email-grid {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: calc(100vh - 100px);

      .overflow-hidden {
        overflow-y: hidden;
        position: relative;
        margin: 10px;

        .button-purple-transparent {
          position: absolute;
          bottom: 10px;
          left: 50%;
          transform: translateX(-50%);
          z-index: 40;
        }

        &::after {
          content: "";
          width: 100%;
          height: 50%;
          position: absolute;
          left: 0;
          bottom: 0;
          background: linear-gradient(to bottom, transparent, rgb(244, 244, 244));
          z-index: 30;
        }
      }

      .overflow-auto {
        overflow-y: auto;
      }
    }

    .use-template-btn-container {
      width: 100%;
      // background-color: white;
      padding: 20px;
      text-align: center;
    }
  }
  
  .temp-detail-left-main {
    height: 100%;
    padding: 55px 40px;
    height: calc(100vh - 200px);

    // overflow-y: auto;

    .temp-detail-inner-content {
      padding: 25px 0px 50px 0px;

      p {
        font-size: 24px;
        white-space: pre-wrap;
        color: var(--beige);
        

        span {
          color: var(--medium-black);
        }

        ul {
          list-style: disc;
          margin-left: 25px;
          color: var(--medium-black);
          display: flex;
          flex-direction: column;
          gap: 20px;
        }
      }

      h5 {
        color: var(--beige);

        h5 {
          color: var(--medium-black);
        }
      }
    }
  }

  .temp-detail-left-main::-webkit-scrollbar {
    width: 6px !important;
  }

  .temp-detail-left-main::-webkit-scrollbar-track {
    background: #f1f1f100;
  }

  .temp-detail-left-main::-webkit-scrollbar-thumb {
    background: #bababa;
    border-radius: 30px;
  }

  .temp-detail-right-main {
    border-radius: 5px 0px 0px 5px;
    background: #e6e5f9;
    width: 25%;
    height: 100%;
    align-self: flex-end;
    padding: 60px 10px;

    .import-img {
      position: relative;
      width: 100%;
      margin: 10px 0px;
      border-radius: 14.972px;
      padding: 5px;
      border: 2px dashed rgb(0, 0, 0);
      overflow: hidden;
      background: rgba(255, 255, 255, 0.7);

      .upload-container {
        padding: 20px 10px;

        .upload-icon {
          width: 60px;
          height: 60px;
        }

        .file-icon {
          font-size: 62px;
          margin: 20px 10px;
        }

        p {
          font-size: 14px;
        }
      }

      .delete-icon {
        position: absolute;
        top: 8px;
        right: 8px;
      }

      .upload-img {
        width: 100%;
        border-radius: 14px;
      }

      #form-file-upload {
        text-align: center;
      }

      #input-file-upload {
        display: none;
      }

      #drag-file-element {
        position: absolute;
        width: 100%;
        height: 100%;
        border-radius: 1rem;
        top: 0px;
        right: 0px;
        bottom: 0px;
        left: 0px;
      }
    }
  }
}

@media only screen and (max-width: 1450px) {
  .template-detail-main-container {
    .temp-detail-left-main {
      width: 75%;

      .temp-detail-inner-content {
        h5 {
          font-size: 16px;
        }
      }
    }

    .temp-detail-right-main {
      width: 30%;
    }
  }
}

@media only screen and (max-width: 1250px) {
  .template-detail-main-container {
    .temp-detail-left-main {
      width: 70%;
      padding: 55px 20px;

      .temp-detail-inner-content {
        h5 {
          font-size: 13px;
        }
      }
    }

    .temp-detail-right-main {
      width: 30%;
    }
  }
}

@media only screen and (max-width: 700px) {
  .template-detail-main-container {
    flex-direction: column;
    gap: 30px !important;

    .temp-detail-left-main {
      width: 100%;
      padding: 55px 20px;

      .temp-detail-inner-content {
        padding: 25px 0px 50px 0px;
        gap: 12px;

        h5 {
          font-size: 10px;
        }

        .party-popper {
          width: 14px;
          height: 14px;
        }
      }
    }

    .temp-detail-right-main {
      width: 100%;
      height: 405px !important;
      padding: 20px !important;
    }
  }
}