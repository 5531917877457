.subscription-main-container {
  border-radius: 10px;
  background: #f2f2f2;
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.15);
  padding: 40px;
  width: 90%;
  margin: 0 20px;

  .pricing-comp {
    gap: 64px;
    border-top: 1px solid #e5e5e5;
    margin-top: 20px;
  }

  .subscription-bottom {
    border-top: 1px solid #e5e5e5;

    button {
      margin-top: 20px;
      border-radius: 10px;
      background: #5e369c;
      padding: 10px;
      width: 264px;
      height: 58px;
      color: #f2f2f2;
      font-size: 24px;
      font-weight: 600;
    }
  }
}


@media only screen and (max-width: 576px) {
  .subscription-main-container {
    box-shadow: none;
    padding: 20px;
  }   
}