.pricingcard-box {
  padding: 28px 25px 28px 31px;
  max-width: 360px;
  min-width: 300px;
  width: 100%;
  border-radius: 27.574px;
  background: #fff;

  .card-top {
    p {
      display: none;
    }
  }

  .card-content {
    margin-top: 27px;

    span {
      gap: 17px;

      label {
        width: 90%;
      }
    }
  }

  .card-bottom-content {
    gap: 14px;
    margin-top: 25px;

    .amt-txt {
      gap: 5px;

      span {
        align-items: baseline;
      }
    }

    .old-price {
      text-decoration: line-through;
      opacity: 0.5;
    }

    button {
      border-radius: 9.191px;
      background: #f8f4ff;
      text-align: center;
      font-size: 18.383px;
      font-weight: 700;
      width: 100%;
      padding: 17px;
      transition: 0.2s ease-in;

      &:hover {
        background: #5e369c;
        color: #f8f4ff;
      }
    }
  }
}

.pro {
  margin-bottom: 32px;
  border-radius: 27.574px;
  background: linear-gradient(88deg, #3f1b78 3.64%, #140231 105.81%);

  .card-top {
    p {
      font-size: 13.787px;
      font-weight: 700;
      padding: 5px 9px;
      border-radius: 4.596px;
      background: #fff;
      display: block;
    }
  }

  h4 {
    color: #fff;
  }

  label {
    color: #fff;
  }

  .card-bottom-content {
    button {
      background: #5e369c;
      color: #fff;
      font-size: 22.979px;
    }
  }

  .active {
    color: #ffffff !important;
  }

  .notactive {
    color: #89879d !important;
  }
}

// @media (max-width:1720px) {
//   .pricingcard-box {
//     .card-content {
//       height: 440px;
//     }
//   }
// }

// @media (max-width:1576px) {
//   .pricingcard-box {
//     .card-content {
//       height: 500px;
//     }
//   }
// }

@media (max-width:1224px) {
  .pricingcard-box {
    padding: 40px 20px;

    .card-bottom-content {
      button {
        font-size: 16.383px;
      }
    }

    .pro {
      margin-bottom: 0px;

      .card-bottom-content {
        button {
          font-size: 18.979px;
        }
      }
    }
  }
}