.previewexcel-modal {

  .ant-modal-close,
  .ant-modal-close:hover {
    color: #c2c2c2;
  }

  .anticon svg {
    width: 27px;
    height: 27px;
  }

  .excelmodal-content {
    background: #2b1254;
    max-height: 80vh !important;
    height: fit-content;
    overflow: hidden;

    .filebanner-banner {
      width: 100%;
      height: 60px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 20px;
      color: white !important;
      padding: 0px 10px;
    }

    .excel-sheet {
      width: 100%;
      max-height: calc(80vh - 100px);
      overflow: auto;
    }

    .excel-sheet::-webkit-scrollbar {
      width: 6px;
    }

    .excel-sheet::-webkit-scrollbar-track {
      background: #f1f1f100;
    }

    .excel-sheet::-webkit-scrollbar-thumb {
      background: #bababa;
      border-radius: 30px;
    }
    .edit-csv {
      position: absolute;
      bottom: 10px;
      right: 10px;
      width: 30px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 30px;
      background-color: gray !important;
      color: white;
      font-size: 20px;
    }
  }
}

@media only screen and (max-width: 800px) {
  .previewexcel-modal {
    width: 90% !important;

    .anticon svg {
      width: 20px !important;
      height: 20px !important;
    }
  }
}